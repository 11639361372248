const demo = {
	root: {
		// minWidth: "768px",
		color: "rgb(255, 255, 255)",
		position: "relative",
	},
	black_content: {
		backgroundColor: "#000 !important",
		paddingTop: "30px",
		"@media (max-width: 738px)": {
			paddingTop: "10px",
		}
	},
	text_content: {
		background: "#474747",
		textAlign: "center",
		padding: "16.5px 40px",
		marginTop: "100px",
		"@media (max-width: 738px)": {
			marginTop: "0"
		}
	},
	text_h2: {
		fontSize: "30px",
		lineHeight: "initial",
		textTransform: "uppercase",
		margin: "8px 0!important",
	},
	text_span: {
		fontFamily: "Cinzel",
		display: "initial",
		fontWeight: 400,
	},
	whitepaper_container: {
		margin: "auto",
		maxWidth: "1060px",
		color: "#000",
		padding: "0 15px"
	},
	hs_responsive_embed_youtube:{
		position: "relative",
		paddingBottom: "56.25%",
		paddingTop: "25px",
	},
	hs_responsive_embed_youtube_iframe:{
		position: "absolute",
		width: "100%!important",
		height: "100%!important",
	}
}

export default demo
