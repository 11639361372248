import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import TopLink from "../../components/TopLink";

import unicryptPageStyles from "../../assets/js/pages/unicryptPageStyles"

import down from "../../assets/images/down.svg";

const useStyles = makeStyles(unicryptPageStyles);

export const Unicrypt = () => {
  const classes = useStyles();
  
  let scrollRef = useRef()
  const executeScroll = () =>  window.scrollTo({ behavior: 'smooth', top: scrollRef.current.offsetTop })
  
  return (
    <div className={classes.root}>
        {/* <TopLink/> */}
      <div className={classes.black_content}>
        <Header/>
        <div className={classes.image_content} />
        <div className={classes.down}>
          <img className={classes.down_icon} src={down} alt="img"  onClick={executeScroll}  />
        </div>
        <div className={classes.text_content}>
          <h2 className={classes.text_h2}>
            <span className={classes.text_span}>ATTENTION SOLDIERS!</span>
          </h2>
        </div>
        <div className={classes.description} ref={scrollRef}>
          <div className={classes.description_parent}>
            <p className={classes.description_p}>
              <span className={classes.white_line}></span><br/>
              And hold onto your helmets as we have some excellent news for vEmpire!
              <br/><br/>
              As you may already be aware, Unicrypt is a decentralised launchpad which has seen countless successful independent crypto projects take off from their platform. However, what not too many may know, is that they are also responsible for incubating & backing only three of the four hundred and fifty projects that have launched through them. Unicrypt apply intense due diligence & research for them to have confidence in the projects they put their name behind. 
              <br/><br/>
              Today soldiers, vEmpire is proud to announce that our VEMP token will be the fourth official incubation of the Unicrypt team. It is an absolute honour to be part of this kind of movement and platform. We honestly cannot wait to be working with such a great team of people.
            </p>
          </div>
        </div>
        <div className={classes.text_content}>
          <h2 className={classes.text_h2}>
            <span className={classes.text_span}>WHAT DOES THIS MEAN?</span>
          </h2>
        </div>
        <div className={classes.description2}>
          <div className={classes.description_parent}>
            <p className={classes.description_p}>
            <span className={classes.white_line}></span><br/>
            This means that vEmpire is now one step closer to accomplishing our mission of invading Decentraland and spreading our pledge & message of removing centralisation from supposedly Decentralised projects. 
            <br/><br/>
            With our sights set on taking on entities such as Metaverse Group and Decentralands Foundation, it is imperative that the vEmpire's VEMP token is minted and secured by a robust network, and who better than one of the largest decentralized exchanges in the world. 
            <br/><br/>   
            With such a prominent backing, vEmpire DDAO and the VEMP token release is going to be revolutionary. vEmpire’s soldiers can rest easy knowing that we have all our communities best interests at heart, and we aim to make you proud on the battlefield by accomplishing our long term mission of driving centralisation & commoditization of users out of the metaverse and out of the Ethereum network.
            <br/><br/>  
            No, Rome was not built in a day, but they laid bricks every minute. The incredible incubator, Unicrypt, is aiding vEmpire in laying a solid foundation. 
            <br/><br/>  
            We look forward to creating more waves in the industry with each battle phase.
            <br/><br/> 
            Long live the Metaverse and long live vEmpire!”
 
            </p>
          </div>
        </div>
      </div>
      
      
      <Footer/>
     
    </div>
  );
};
