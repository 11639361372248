import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import TopLink from "../../components/TopLink";

import whatisnftPageStyles from "../../assets/js/pages/whatisnftPageStyles"


const useStyles = makeStyles(whatisnftPageStyles);

export const WhatIsNft = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
        {/* <TopLink/> */}
      <div className={classes.black_content}>
        <Header/>
        <div className={classes.text_content}>
          <h2 className={classes.text_h2}>
            <span className={classes.text_span}>WHAT IS AN NFT?</span>
          </h2>
        </div>
      </div>
      <div className={classes.whitepaper_container}>
        <br/><br/><br/><br/><br/>
        <p className={classes.paragragh_bold}>What is an NFT?</p>
        <p className={classes.paragragh}>
          An NFT, standing for Non-Fungible Token, is a digital piece of information or a file that is 100% unique. It is the digital equivalent of a one-of-a-kind painting, a photograph, or a collectible card. All NFTs are part of a blockchain, many of them belonging to the Ethereum blockchain specifically.
          <br/><br/>NFTs really hit the mainstream headlines when Beeple (Mike Winkelmann) sold an NFT of one of his pieces of work for $69 million at the famous Christie’s auction house.
        </p>
        <p className={classes.paragragh_bold}>
          How do NFTs work?
        </p>
        <p className={classes.paragragh}>
          Much of the confusion around NFTs comes with understanding why images and videos, amongst other forms of media, can be sold as a one-off NFT, yet still be downloaded and consumed by anyone else.
          <br/><br/>It comes down to ownership of the piece of work as a “token”. Despite anyone else being able to share and download the work, the token has a unique digital certificate that is registered to the purchaser of the NFT, showing that the rights to the token belong to them and no one else.
          <br/><br/>Another use-case for NFTs is as a rewards scheme. Many decentralised finance protocols have made use of this. For example, on the Stake Dao protocol, users can stake the governance token, xSDT, in their “Palace”, which as well as yielding rewards in their native token, generates points that can be spent on various levels of NFTs that are limited in number. Many NFTs are for bragging rights, but there is also scope to provide the owner of the NFT with a boost to their overall staking rewards.
          <br/><br/>One sector that NFTs are beginning to work their way into is gaming. Games can give players the opportunity to buy and own (not just use) certain assets, which they then have the ability to hold onto and use indefinitely, or to barter with other players and sell them on.
          <br/><br/>vEmpire have a trading card game that features NFT versions of certain cards that have powerful abilities not available to other cards, giving the owner advantages in battle against their opponents.
        </p>
        <p className={classes.paragragh_bold}>
        How to buy NFTs?
        </p>
        <p className={classes.paragragh}>
         You can buy NFTs just like you would buy an ordinary cryptocurrency. The only difference is that instead of using a cryptocurrency exchange, you would use an NFT marketplace like OpenSea or Rarible. In order to buy an NFT, individuals need to transact in cryptocurrencies (normally ETH) and they must have a web 3.0 wallet such as MetaMask.
          Turn on screen reader support
        </p>
        <p className={classes.paragragh_bold}>
          So why buy an NFT?
        </p>
        <p className={classes.paragragh}>
          An NFT could be viewed the same way as a valuable painting. Art dealers often buy a one-off painting before flipping it for (often astronomical) profit once the time is ripe and the piece has garnered more interest and value. NFTs are really just a digital version of the same thing.
          <br/><br/>However, not all NFTs are highly valuable pieces. You can buy reasonably priced items on sites like Opensea.io and digitaltradingcards.com and hold onto them like you would hold a crypto coin in the hope of rising value.
        </p>
        
        <p className={classes.paragragh_bold}>
          Summary
        </p>
        <p className={classes.paragragh}>
          I think Arthur C Clarke’s three laws summarise the naysayers of these collectibles perfectly, especially in his third law;
        </p>
        <p className={classes.paragragh_intend}>
        1. When a distinguished but elderly scientist states that something is possible, he is almost certainly right. When he states that something is impossible, he is very probably wrong.
        <br/><br/>2. The only way of discovering the limits of the possible is to venture a little way past them into the impossible.
        <br/><br/>3. Any sufficiently advanced technology is indistinguishable from magic.
        </p>
        <p className={classes.paragragh}>
          Humans have always struggled to comprehend the future when shown it in the present, and we believe that the very same forces may well be happening now with NFTs.
        </p>
      </div>
      <br/><br/><br/>
      <Footer/>
     
    </div>
  );
};
