import vEmpire_logo_large from "../../images/vEmpire_logo_large.jpeg";

const whitepaperPageStyles = {
	root: {
		// minWidth: "768px",
		color: "rgb(255, 255, 255)",
		position: "relative",
	},
	black_content: {
		backgroundColor: "#000 !important",
		paddingTop: "30px",
		"@media (max-width: 738px)": {
			paddingTop: "10px",
		}
	},
	image_content: {
		backgroundImage: `url(${vEmpire_logo_large}) !important`,
		borderStyle: "solid !important",
		borderWidth: "0 !important",
		minHeight: "736px",
		backgroundRepeat: "no-repeat !important",
		backgroundSize: "contain !important",
		backgroundPosition: "50% 50%!important",
		width: "auto !important",
		maxWidth: "100% !important",
		margin: "0px auto 50px !important",
		minWidth: "0 !important",
		textAlign: "start !important",
		"@media (min-width: 1200px)": {
			// transform: "translateY(-64px)"
		},
		"@media (max-width: 738px)": {
			minHeight: "100vw",
		}
	},
	down:{
		textAlign: "center"
	},
	down_icon:{
		width: "75px",
		cursor: "pointer"
	},
	download_parent:{
		textAlign: "center"
	},
	download_link: {
		color: "#000",
        padding: "12.5px 50px",
        fontSize: "14px",
        backgroundColor: "#fff",
        borderRadius: "25px",
        cursor: "pointer",
		marginRight: "13px",
		fontFamily: "Cinzel",
		fontWeight: 500,
		textDecoration: "none",
		display: "inline-block",
		marginBottom: "15px",
	},
	text_content: {
		background: "#474747",
		textAlign: "center",
		padding: "16.5px 40px",
	},
	text_h2: {
		fontSize: "30px",
		lineHeight: "initial",
		textTransform: "uppercase",
		margin: "8px 0!important",
	},
	text_span: {
		fontFamily: "Cinzel",
		display: "initial",
		fontWeight: 400,
	},
	
	form_section:{
		"@media (max-width: 738px)": {
			textAlign: "center !important",
			padding: "0 20px",
		}
	},
	menulink:{
		"@media (max-width: 738px)": {
			display: "block",
		}
	},
	textAlignRight: {
		textAlign: "right",
	},
	recaptcha: {
		position: "absolute",
		bottom: "10px",
		left: "0px",
	},
	dialogWrapper: {},
	whitepaper_container: {
		margin: "auto",
		maxWidth: "1165px",
		color: "#000",
		padding: "0 15px"
	},
	main_title:{
		fontSize: "30px",
		fontWeight: "700",
		fontFamily: "Cinzel",
		textAlign: "center"
	},
	main_title: {
		fontSize: "30px",
		fontWeight: "700",
		fontFamily: "Cinzel",
		textAlign: "center"
	},
	sub_title: {
		fontSize: "30px",
		fontWeight: "500",
		fontFamily: "Cinzel",
		textAlign: "center"
	},
	description: {
		fontSize: "20px",
		fontFamily: "Poppins",
		fontWeight: "400",
		textAlign: "center",
		marginBottom: "8px"
	},
	email_parent:{
		textAlign: "center",
		marginBottom: "40px"
	},
	email: {
		fontSize: "18px",
		fontFamily: "Poppins",
		textAlign: "center",
		color: "#474747"
	},
	paragragh: {
		fontSize: "18px",
		fontWeight: "500",
		fontFamily: "Poppins",
		marginBottom: "1.8rem"
	},
	paragragh_bold: {
		fontSize: "18px",
		fontFamily: "Poppins",
		fontWeight: 700
	},
	paragragh_big: {
		fontSize: "25px",
		fontFamily: "Poppins",
		fontWeight: 500
	},
	paragragh_medium: {
		fontSize: "18px",
		fontFamily: "Poppins",
		fontWeight: 600
	},
	paragragh_italic: {
		fontSize: "18px",
		fontStyle: "italic",
		fontFamily: "Poppins",
		fontWeight: 500
	},
	link:{
		fontFamily: "Poppins",
		fontSize: "18px",
		color: "#474747",
		wordBreak: "break-all"
	},
	row:{
		display: "flex",
		justifyContent: "space-between",
		"@media (max-width: 738px)": {
			flexDirection: "column"
		}
	},
	image_fluid: {
		maxWidth: "100%",
		width: "1165px"
	},
	table_responsive:{
		display: "block",
		width: "100%",
		overflowX:" auto",
	},
	table1:{
		borderSpacing: "0",
		maxWidth: "791px",
		width: "100%",
		'& td':{
			paddingBottom: "1rem",
			fontSize: "18px",
			fontWeight: "500",
			fontFamily: "Poppins",
		}
	},
	table2:{
		borderSpacing: "0",
		maxWidth: "791px",
		width: "100%",
		'& td':{
			paddingBottom: "1rem",
			fontSize: "18px",
			fontWeight: "500",
			fontFamily: "Poppins",
			padding: "24px 13px"
		}
	},
	minus:{
		fontSize: "40px",
		fontFamily: "Poppins",
		fontWeight: 500,
		display: "inline-block",
    	marginLeft: "85px",
		"@media (max-width: 738px)": {
			marginLeft: "0px",
			fontSize: "18px",

		}
	},
	formula:{
		fontSize: "40px",
		fontFamily: "Poppins",
		fontWeight: 500,
		display: "inline-block",
    	marginLeft: "85px",
		"@media (max-width: 738px)": {
			marginLeft: "0px",
			fontSize: "18px",
		}
	},
	border_right:{
		borderRight: "1px solid #000",
	},
	border_bottom_right:{
		borderRight: "1px solid #000",
		borderBottom: "1px solid #000",
	},
	border_bottom:{
		borderBottom: "1px solid #000",
	},
	threedots:{
		textAlign: "center",
		margin: "40px 0"
	},
	dot1:{
		background: "#000",
		width: "3px",
		height: "3px",
		position: "relative",
		display: "inline-block",
		left: "30px"
	},
	dot2:{
		background: "#000",
		width: "3px",
		height: "3px",
		position: "relative",
		display: "inline-block"
	},
	dot3:{
		background: "#000",
		width: "3px",
		height: "3px",
		position: "relative",
		display: "inline-block",
		right: "30px"

	},
}

export default whitepaperPageStyles
