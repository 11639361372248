import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";
import ConnectWalletButton from "../../components/ConnectWalletButton";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import TopLink from "../../components/TopLink";
import homePageStyles from "../../assets/js/pages/homePageStyles"
import unicrypt from "../../assets/images/unicrypt.png";
import yahoofinance from "../../assets/images/yahoo-finance.png";
import forbes from "../../assets/images/forbes.png";
import marketwatch from "../../assets/images/marketwatch.png";
import benzinga from "../../assets/images/benzinga.png";
import bitcoinist from "../../assets/images/bitcoinist.png";
import video from "../../assets/video.mp4";
import videoMobile from "../../assets/video-mobile.mp4";
import opensea from "../../assets/images/opensea-logo.png";
import unc from "../../assets/images/unc.png";
import uncl from "../../assets/images/uncl.png";


const useStyles = makeStyles(homePageStyles);

export const Home = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {/* <TopLink/> */}
      <div className={classes.black_content}>
        {/* <video className={classes.video} autoPlay muted loop playsInline type='video/mp4'>
          <source src={video} type="video/mp4"></source>
        </video> */}
        <Header/>
       
        <div className={classes.image_content}>
        </div>
        
        <div className={classes.featured_parent}>
          <div className={classes.featured}>
            <a className={classes.featured_item} href="https://finance.yahoo.com/news/mexc-global-strikes-partnership-decentralization-143600151.html" target="_blank"><img className={classes.featured_logo} src={yahoofinance} alt="img" /></a>
            <a className={classes.featured_item} href="https://www.forbes.com/sites/cathyhackl/2021/07/25/metaverse-weekly-snaps-new-ar-aquisition-roblox-launches-podcast-facebook-will-be-a-metaverse-company-fox-announces-avatar-signging-competition-and-more/?sh=548a7877f1e4" target="_blank"><img className={classes.featured_logo} src={forbes} alt="img" /></a>
            <a className={classes.featured_item} href="https://opensea.io/collection/vempire-the-founding-soldiers" target="_blank"><img className={classes.featured_logo} src={opensea} alt="img" /></a>
            <a className={classes.featured_item} href="https://www.marketwatch.com/press-release/vempire-to-launch-ddao-nft-and-token-this-month-august-2021-2021-08-02?tesla=y" target="_blank"><img className={classes.featured_logo} src={marketwatch} alt="img" /></a>
            <a className={classes.featured_item} href="https://www.benzinga.com/pressreleases/21/08/g22269086/vempire-to-launch-ddao-nft-and-token-this-month-august-2021" target="_blank"><img className={classes.featured_logo} src={benzinga} alt="img" /></a>
            <a className={classes.featured_item} href="https://bitcoinist.com/mexc-global-strikes-a-partnership-with-decentralization-upholders-vempire-ddao/" target="_blank"><img className={classes.featured_logo} src={bitcoinist} alt="img" /></a>
          </div>
        </div>
        <div className={classes.text_content}>
          <h2 className={classes.text_h2}>
            <span className={classes.text_span}>
            <span className='font_size_small'>v</span>Empire - THE FRONTLINE IN THE FIGHT AGAINST CENTRALISATION</span>
          </h2>
        </div>
      </div>
      
      <div className={classes.footer_unicrypt}>
        <span className={classes.footer_text}>An Incubated Launch on Unicrypt</span>
        <img className={classes.footer_logo} src={unicrypt} alt="img" />
      </div>
      <Footer/>
     
    </div>
  );
};
