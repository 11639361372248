const TopLinkStyles = {
	show:{
		display: "block !important"
	},
	top_link:{
		background: "#474747",
		textAlign: "center",
		padding: "10px",
		display: "none",
		// position: "absolute",
		width: "100%"
	},
	top_link_a:{
		color: "#fff",
		fontFamily: "Poppins",
		fontSize: "15px",
		textDecoration: "none"
	},
	close_icon:{
		cursor: "pointer",
		verticalAlign: "bottom"
	}
}

export default TopLinkStyles
