import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import TopLink from "../../components/TopLink";

import demo from "../../assets/js/pages/demo"
import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";

const useStyles = makeStyles(demo);

export const Demo = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
        {/* <TopLink/> */}
      <div className={classes.black_content}>
        <Header/>
        <div className={classes.text_content}>
          <h2 className={classes.text_h2}>
            <span className={classes.text_span}>Demo</span>
          </h2>
        </div>
      </div>
      <div className={classes.whitepaper_container}>
        <br/><br/><br/>
        <div className={classes.hs_responsive_embed_youtube}>
          <iframe src="https://www.youtube.com/embed/Hfq78cyNzi0" className={classes.hs_responsive_embed_youtube_iframe} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </div>
      <br/><br/><br/>
      <Footer/>
     
    </div>
  );
};
