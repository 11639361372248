import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { makeStyles } from "@material-ui/core/styles"

import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'
import ClearIcon from '@material-ui/icons/Clear';

import { Web3ReactProvider, useWeb3React, UnsupportedChainIdError } from '@web3-react/core'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import DialogButton from "./DialogButton"

import MetamaskIcon from "../assets/images/metamask.png"
import LedgerkIcon from "../assets/images/ledge.png"
import WalletkIcon from "../assets/images/walletconnect-circle.png"

import { connectorsByName } from '../config/constants'

import { updateProfileAsync } from '../state/actions'
import ConnectWalletButtonStyles from "../assets/js/components/ConnectWalletButtonStyles"

import axios from "axios";
const useStyles = makeStyles(ConnectWalletButtonStyles)

function ConnectWalletButton(props) {
    const context = useWeb3React()
    const { account, activate } = context
    const dispatch = useDispatch()

    const { text } = props

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const Connect = (connectionName) => {        
        activate(connectorsByName[connectionName])
        handleClose()
    }

    const saveAddress = async (address) => {
        try {
           let response = await axios.post("https://api.v-empire.digital/api/v1/wallets/save", { address: address });
            response = response.data;
        } catch (error) {
          console.log(error);
        }
      };

      const maskedAccount = () => {
        return account.substr(0,4)+ "****" + account.slice(account.length - 4)

      };

    useEffect(() => { 
        console.log(account)
        if (account){
            saveAddress(account);
            dispatch(updateProfileAsync({data: account}))
        }
            
    }, [dispatch, account])

    const classes = useStyles();
    
    return (
        <>
            <button className={ classes.btn } onClick={handleClickOpen}>
                { account ? maskedAccount() : text }
            </button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                className={classes.dialogWrapper}
            >
                <DialogTitle className={ classes.titleWrapper }>
                    <p className={ classes.titleContent }>
                        <span className={ classes.textColor }>Select wallet</span>
                        <span className={ classes.closeButton }>
                            <ClearIcon className={ classes.icon + " " + classes.buttonColor } onClick={ handleClose }/>
                        </span>
                    </p>
                </DialogTitle>
                <DialogContent>                   
                    <div className={ classes.dialogWrapper }>
                        <div className={ classes.dialogRow }>
                            <DialogButton text="Metamask" icon={ MetamaskIcon } handleClick={ () => Connect('Injected') }/>
                            <DialogButton text="Wallet Connect" iconClasses={ classes.walletIcon } icon={ WalletkIcon } handleClick={ () => Connect('WalletConnect') } />
                            <DialogButton text="Ledger" icon={ LedgerkIcon } handleClick={ () => Connect('Ledger') } />
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
        
    )
}

export default ConnectWalletButton
