import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import TopLink from "../../components/TopLink";

import missionPageStyles from "../../assets/js/pages/missionPageStyles"
import down from "../../assets/images/down.svg";


const useStyles = makeStyles(missionPageStyles);

export const Mission = () => {
  const classes = useStyles();

  let scrollRef = useRef()
  const executeScroll = () =>  window.scrollTo({ behavior: 'smooth', top: scrollRef.current.offsetTop })
  return (
    <div className={classes.root}>
        {/* <TopLink/> */}
      <div className={classes.black_content}>
      <Header/>
        
        <div className={classes.image_content} />

        <div className={classes.down}>
          <img className={classes.down_icon} src={down} alt="img"  onClick={executeScroll}  />
        </div>
        <div className={classes.text_content}>
          <h2 className={classes.text_h2}>
            <span className={classes.text_span}><span className='font_size_small'>v</span>Empire Is Preventing A Metaverse Financial Crisis</span>
          </h2>
        </div>
      </div>
      <div className={classes.whitepaper_container} ref={scrollRef}>
       
      <div className={classes.by}>By Romulus
        </div>
        <span className={classes.line}></span>
        <div className={classes.introduction}>Introduction & Context
        </div>
        <p className={classes.paragragh}>
        As a late millennial, my recollection of The Great Recession is foggy. As a teenager during that time, I remember constantly hearing about it on the news and thinking that Credit Crunch would make a great cereal. It is only after moving into financial services I began to understand just how much the world was railroaded by rating agencies, investment firms & banks
        <br/><br/>I think I first realised that the financial services industry/sector was a joke, when the advice I was given by my then Senior Trader at my first stockbroker job at nineteen was to make sure I go long and short with different clients, as that way we will make our 20% profit related charge on at least one of them
        <br/><br/>Or it could be when the bosses’ favourite traders weren’t the good ones who made the clients’ money, the favourites were the psycho & sociopaths who would, as they called it in the industry, “churn clients’ accounts”. Churning is where clients are chopped in and out of trades over and over to take advantage of the entry and exit fees. The result traders would strive for is 100% account billing. This means if a client opened a £10,000 account, the desirable outcome for the trader was to charge that client £10,000 in fees. I remember a profitable account being a rarity
        <br/><br/>Needless to say, eventually, regulation caught up and restrictions were put in (not that they did much about the sharks in the industry), but they at least capped exposure & leverage to de-risk these client accounts somewhat
        <br/><br/>Fast forward five years and now metaverse & NFT projects are finally picking up steam. Decentraland completes its MANA initial coin offering in August of 2017 and raises approximately $24 million worth of various cryptocurrencies. It takes until February of 2020 to complete the metaverse and prepare entry to the thousands of eagerly awaiting fans, gamers, and thrill seekers
        <br/><br/>Just like in the 90s when more and more money poured into the music industry, the independence and the quality fell by the wayside as giant record labels monopolised the market and commoditized the artists, independence was out, and generic commoditization was in
        You would be one of few if you could name me a band, an actual group of instrument players with one or more vocalists, who write their own songs, that have topped the charts consistently since the late 2010s & into the early 2020’s. And no, Ed Sheeran is not a band. No hate for Ed though, but based on his new single “Bad Habits”, I think we may be bidding farewell.
        </p>
      </div>
      <br/><br/><br/>
      <div className={classes.text_content}>
        <h2 className={classes.text_h2}>
          <span className={classes.text_span}>Gee whizz….. we can’t get away with <br/>that in the real world anymore?</span>
        </h2>
      </div>
      <div className={classes.whitepaper_container} >
       
        <br/><br/><br/><br/><br/>
        <p className={classes.paragragh}>
        <a href="https://metaversereit.com/about-us/offering-approach" className={classes.link}>https://metaversereit.com/about-us/offering-approach</a>
        <br/><br/> “The REIT intends to be represented by an NFT token with each token representing a relative ownership of the total portfolio of assets under management (AUM). The more NFT tokens that are owned, the larger percentage of the REIT an investors owns. We intend for this to be by far the most pragmatic, cost effective and safe method of gaining investment exposure to a diversified basket of virtual real estate NFT’s.”
        <br/><br/>If you follow me or vEmpire on Twitter (@dominicVEMP @vEmpiredigital), or have read my White Paper, you will be aware that Metaverse Group already have a powerful monopoly over the….. well Metaverse, (I’m going to refer to them as MG & MV now)
        <br/><br/>MG have fingers royally stuffed into many pies, with Decentraland, Sandbox, Somnium Space, Upland, cryptovoxels, Decentral Games, Vegas City, Artie, Metalith, & Atari all listed as ventures and investments on their website. In the real world, antitrust & competition laws prohibit, or try to prohibit, these types of monopolies.
        As stated on their site “Metaverse Group’s interests are fully aligned with REIT holders” so why would somebody invest into a real estate investment trust? And what are MG aligning themselves with?
      
        </p>
        <p className={classes.paragragh_intend}>
        - Tax advantages — When an investment trust sells shares it is not taxed on the capital gains it’s made, unlike direct investment made by private investors.
        <br/><br/>
        - Ability to borrow for better, or worse, potential returns — The trust’s manager can borrow money to increase your market exposure. This is called ‘gearing’ and can help boost returns in rising markets or increase losses in bear markets. If a trust borrows the equivalent of 10% of its assets, then it will be 10% geared.
        <br/><br/>
        -A real estate investment trust (REIT) is a company that owns, operates, or finances income-producing properties. By law, 90% of a REIT’s profits must be distributed as dividends to shareholders.
        </p>
        <p className={classes.paragragh}>
        Who needs laws when you don’t have shareholders to distribute to anyway?! We have already seen in my White Paper that Metaverse group leases individual LAND parcels, selling both the rights to lease the land, plus keeping 80% of the profits, these leases & rights are sold as NFTs and represent part of the virtual casinos….. so metaverse owns the casino which is also an Estate NFT… This is layer one and the beginning of the synthetic NFT chain.
        <br/><br/>As Michael Borg & Jason Cassidy were so keen to tell us, this investment trust won’t have shareholders (won’t have to follow that pesky 90% profit law this way) “The REIT intends to be represented by an NFT token with each token representing a relative ownership of the total portfolio of assets under management.
        <br/><br/>So, the investment trust that owns NFTs which make an income from leasing NFTs will be itself, represented by an NFT, only this NFT could represent leveraged, “geared” assets. Right.
        <br/><br/>Of course, Ethereum is decentralized and the beauty of blockchain technology is its ability to monitor transactions and proof of ownership. So, let’s fast forward a year and I’m earning so much from my REIT NFT that I decide to hatch a cunning plan…. Eureka! I can just do what MG did and lease my NFT, keeping a percentage of the profits whilst also selling the yearly rights. I’m a stone-cold genius.
        <br/><br/>Just like synthetic CDOs in the great financial crisis, we are once again relying on investment companies to not get greedy and over leverage themselves. Let’s say MG get greedy or any other potential future virtual LAND REITs do, these centralised entities who have gone and screwed themselves will be coming after you. That’s right, just like 2008, the NFTs they have leased which have been further leased and leased, they suddenly need back to cover the hole that they have got themselves into.
        <br/><br/>Panic selling inevitably sets in and brings the metaverse properties, currencies and even the metaverse projects themselves, to their knees after investment & venture capital funds pull out of fundraising rounds, sell stakes for below market rates taking years of progress away from the virtual world, plus the time spent to recover. Once again it is the thousands of users and the population of the metaverses that will feel the brunt of the pain.
        <br/><br/>Of course everything above is subjecture and opinion with some speculation sprinkled in for good measure. The MG REIT hasn’t clarified its regulatory status yet for example. However, the foundations are laid, and the groundwork is there for the Metaverse to experience the first ever virtual world financial crisis.
        <br/><br/>We have a chance however to intervene. Decentralisation has no laws, and it is up to the users and the community to police their protocols. vEmpire was formed to highlight and bring about this change and encourage communities to actively engage and spot when they may have malevolent forces at work in their worlds or networks.
        <br/><br/>If vEmpire has invaded your protocol, you have already been invaded by an entity far darker. Centralisation
        </p>
      </div>
      <br/><br/><br/><br/>
      <Footer/>
     
    </div>
  );
};
