import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Footer from "../../components/Footer";
import Header from "../../components/Header";
import TopLink from "../../components/TopLink";

import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";
import whitepaperPageStyles from "../../assets/js/pages/whitepaperPageStyles"
import graphic_1 from "../../assets/images/graphic_1.svg";
import graphic_2 from "../../assets/images/graphic_2.svg";
import graphic_3_1 from "../../assets/images/graphic_3.1.svg";
import graphic_3_2 from "../../assets/images/graphic_3.2.svg";
import graphic_4 from "../../assets/images/graphic_4.svg";
import graphic_5 from "../../assets/images/graphic_5.svg";
import down from "../../assets/images/down.svg";


const useStyles = makeStyles(whitepaperPageStyles);

export const Whitepaper = () => {
  const classes = useStyles();

  let scrollRef = useRef()
  const executeScroll = () =>  window.scrollTo({ behavior: 'smooth', top: scrollRef.current.offsetTop })
  return (
    <div className={classes.root}>
        {/* <TopLink/> */}
      <div className={classes.black_content}>
        <Header/>
        
        <div className={classes.image_content} />
        <div className={classes.download_parent} >
          <a href="/vEMPIRE White Paper.pdf" download className={classes.download_link} >Download Whitepaper as PDF</a>
        </div>
        <div className={classes.down}>
          <img className={classes.down_icon} src={down} alt="img"  onClick={executeScroll}  />
        </div>
        <div className={classes.text_content}>
          <h2 className={classes.text_h2}>
            <span className={classes.text_span}>The Front line in the fight against centralisation</span>
          </h2>
        </div>
      </div>
      <div className={classes.whitepaper_container} ref={scrollRef}>
        <h1 className={classes.main_title}>vEMPIRE DDAO</h1>
        <h3 className={classes.sub_title}>
          A Democratic Decentralised Autonomous Organisation & The Front Line Against Centralisation
        </h3>
        <h5 className={classes.description}>
          Whitepaper by Romulus & Remus, written by Dominic Ryder
        </h5>
        <div className={classes.email_parent}>
          <a className={classes.email} href="mailto:romulus@v-empire.digital">romulus@v-empire.digital</a>
        </div>
        <p  className={classes.paragragh}>
          This document serves to outline the vEmpire DDAO, a new, fairer system of DAO which rewards honour and loyalty to the DDAO in more or equal measure to monetary contributions. The Empire's goal is to highlight centralisation in supposedly decentralised projects, & bring power back to the communities where their founders, contributors and whales have and will not, relinquish control.
          <br/><br/>
          The Empire serves to invade and remind these projects that stakeholders share equal value to token owners. Moreover, decentralisations lack of regulation can, and has, shown to lead to monopolies when unchallenged by the community. This is especially prevalent where voting rights, coins, and/or NFTs are centred around a small percentage of high token wallets.
          <br/>
          The Empire's first conquest & invasion will be the metaverse & metaverse projects. Here we establish our base and begin the elimination of centralisation from the decentralised world. There are many reasons for entering the metaverse as our initial conquest;
          <br/><br/>
          1) The Empire is open to diplomatic solutions and so we plan to establish an embassy.
          <br/><br/>
          2) Upon scouting of these projects, we have found them to be straying from their values and allowing centralised entities to take an unreasonable share of power from the communities they serve.
          <br/><br/>
          3) The monopolies and corporates in these metaverses have so much power that they are now leasing property, keeping up to 80% plus of the profits whilst leaving the users & community with a fractional percentage.
          <br/><br/>
          4) The gaming community in the real world has always been shoehorned into using the same large corporate gaming providers. Independent developers & creators have been pushed out and forced into wage labour for these gaming giants.
          <br/><br/>
          The Empire cannot allow the gaming community to be forced, again, (but now in the virtual world) into the hands of these gaming giants.
          <br/><br/>
          
          The vEmpire DDAO distributes value generated by a basket of pools and LP services to stakeholders. The DDAO functions as a cooperative, whereby stakeholders earn vEmpire’s token (VEMP) for providing collateral and, via a staking mechanism, receive a share of the fee revenues generated by supported DeFi services, pools, NFTs and any fees generated from the DDAOs contributions on the platform or in any metaverse.
          <br/><br/>
          The VEMP work token effectively encapsulates the intrinsic value of the VEMP services basket. The VEMP token can be staked into xVEMP to grant pro-rata governance rights over all operation concerns of the DeFi services’ provision. Income generated for the Empire will be gifted to xVEMP holders. Staking derivatives will also be enabled via locked pools on top of the supported DeFi protocols.
        </p>
        <div className={classes.threedots}>
          <span className={classes.dot1}></span>
          <span className={classes.dot2}></span>
          <span className={classes.dot3}></span>
        </div>
        <br/>
        <p className={classes.paragragh_bold}>
          1.1 - The VEMP Tokens, vEmpire Gamer Token
          <br/><br/>VEMP 
          <br/><br/>xVEMP
          <br/><br/> xsVEMP 
          <br/><br/>The Empire's Invasion of Decentraland
          <br/><br/>1.3 - The DDAO - Democratised decentralized autonomous organization 
          <br/><br/>1.4 - Token distribution and protecting our Soldiers 
          <br/><br/>1.5 - VEMP Token Airdrop 
          <br/><br/>2.1 The Empire compounds APY inside strategies whilst you compound outside 
          <br/><br/>2.2 - Incentivising MANA onto the platform 
          <br/><br/>2.3 - LAND leasing, buying & maintenance of the DDAO 
          <br/><br/>3.1 - Metaverse limitations 
          <br/><br/>3.2 Vegas City, Metaverse Group/Ventures & Founders who won’t let go 
          <br/><br/>3.3 - Development schedule
          <br/><br/>4.1 - Tokenomics & emission schedule 
          <br/><br/>4.2 - Inflation Table 
          <br/><br/>5.1 - The Battles & Playing Card Game 
          <br/><br/>5.2 - The Empires Gladiators and the kings of men 
          <br/><br/>5.3 - Fair Game 
          <br/><br/>5.4 - Lore 
          <br/><br/>5.5 - Trading Card Games 
          <br/><br/>5.6 - Wearables & No Soldier Left Behind <br/>Card proof of concept 
          <br/><br/>6.0 - Disclaimer 
          <br/><br/>
        </p>
        <div className={classes.threedots}>
          <span className={classes.dot1}></span>
          <span className={classes.dot2}></span>
          <span className={classes.dot3}></span>
        </div>
        <p className={classes.paragragh_bold}>
         
          1.1 - The VEMP Tokens - vEmpire Gamer Token
          <br/><br/>
          VEMP
        </p>
        <p className={classes.paragragh}>
          Integral to the protocol is the VEMP token. This is the native token for the Empire and is the currency used to reward trading card battle winners, liquidity providers, stakers and the DDAO. At present, decentraland only has MANA being used on world for content. Eventually, we would hope to be in a position to influence VEMP onto the platform as well.
          <br/><br/>Upon successful launch of V2, the Land and Estates purchased will be monetized and rebuilt in the aesthetiques of the Empire. All profits will be distributed to the DDAO. The first parcels of land are likely to be;
          <br/><br/>-An Embassy & NFT art gallery for purchase of trading cards & vEmpire wearables
          <br/>-A Colosseum, gladiator gambling and NFTs
          <br/>-Chariot Racing Track, gambling and Charioteer NFTs
          <br/>-Army Barracks & Bedsit, MANA charged per “nights stay”, VEMP drop into wallet,
          <br/>effectively a foreign exchange service until Decentraland accept VEMP
        </p>
        <p className={classes.paragragh_bold}>
          xVEMP - Governance Token
       </p>
       <p className={classes.paragragh}>
        VEMP holders can stake their tokens for xVEMP, this will earn them rewards and voting rights in the DDAO, which will give them control of the Empire's invasion, conquest and expansion.
        <br/><br/>
          As profits from battles and later, monetized land, gets gifted to the DDAO (xVEMP holders), the value of xVEMP will rise, meaning rewards are harvested upon unstaking from the DDAO. This rewards loyalty to the Empire as early stakers will benefit as illustrated by the equation below;
          <br/><br/><br/>

          The DDAO (as an example) sits at 30% APY which is gifted over the next 6 months<br/>
          6 months later a new Soldier joins the Empire and stakes 1 VEMP<br/>
          This VEMP is worth 0.836 xVEMP as the xVEMP has had 6 months of rewards already paid at 30% APY<br/>
          1 Year later (6 months on from the example directly above) at the same APY, 1 VEMP is now worth 0.7 xVEMP.<br/>
          This incentivises early adoption as xVEMP should only increase in value against VEMP
       </p>

      <p className={classes.paragragh}>
      R = Interest Rate as a decimal
        
      <br/>T = Years

      </p>
      <p className={classes.paragragh_big}>
        (1-R)^(1*T )

      </p>
        <div className={classes.row}>
          <p className={classes.paragragh}>
            For examples below T = 6 months = 0.5      
            
          </p>
          <p className={classes.paragragh}>   
          (1VEMP-0.3)^(1*0.5)  = 0.836 xVEMP
          </p>
        </div>
        <div className={classes.row}>
          <p className={classes.paragragh}>
             For examples below T = 1 Year = 1    
          </p>
          <p className={classes.paragragh}>   
            (1VEMP-0.3)^(1*1) = 0.7 xVEMP
          </p>
        </div>
        <p className={classes.paragragh}>
          <br/><br/>
          DDAO Launches - 1 VEMP = 1 xVEMP
          <br/>Day 1 Soldier stakes and receives the above as no rewards have yet been distributed

          <br/><br/>The DDAO (as an example) sits at 30% APY which is gifted over the next 6 months
          <br/><br/>6 months later a new Soldier joins the Empire and stakes 1 VEMP
          <br/>This VEMP is worth 0.836 xVEMP as the xVEMP has had 6 months of rewards already paid at 30% APY
          <br/><br/>1 Year later (6 months on from the example directly above) at the same APY, 1 VEMP is now worth 0.7 xVEMP.
          This incentivises early adoption as xVEMP should only increase in value against VEMP
        </p>
        <p className={classes.paragragh_bold}>
          xsVEMP - Battle Token
        </p>
        <p className={classes.paragragh}>
        For soldiers to train and fight battles xVEMP is allocated into pools which will effectively burn a percentage of the tokens and reward them to the battle winners. The pools will differ in percentage burns so people can take as much risk as they please. See section 5.1 for details
        </p>
        <p className={classes.paragragh_bold}>
        1.2 - The Empires invasion of Decentraland
        </p>
        <p className={classes.paragragh_medium}>
        Why Decentraland? They have a DAO?
        </p>
        <p className={classes.paragragh}>
        The Empire’s mission is to remind decentralised entities across the blockchain universe of their commitment to their users and communities. There are a few areas where Decentraland has strayed from this
        </p>
        <p className={classes.paragragh}>
          <a className={classes.link} href="https://decentraland.org/blog/announcements/security-council/">https://decentraland.org/blog/announcements/security-council/</a>
        </p>
        <p  className={classes.paragragh_italic}>
          “Aside from the DAO, we think there should be a group of people tasked with preventing and handling security issues on LAND and Estate contracts. This group of people, which we’re calling the Security Advisory Board, will effectively act as guarantors of contract security, tasked with the job of providing swift response to bug reports.”
        </p>
        <p className={classes.paragragh}>
          The announcement above from Decentraland introducing the security council was based on responding to tech security. However the council has now evolved to overseeing all proposals & these five individuals deciding what is and isn’t; “badly intended or that could harm the ecosystem”
        </p>
        <p className={classes.paragragh}>
        The Empire wishes the security council return to its previous purpose.
        </p>
        <p className={classes.paragragh}>
          <a className={classes.link} href="https://docs.decentraland.org/decentraland/community-voting/">https://docs.decentraland.org/decentraland/community-voting/</a>
        </p>
        <p className={classes.paragragh}>
          We would like to take this opportunity to assure the security council that we have nothing but good intentions and will cause no harm. Our purpose is to give Decentraland back its namesake, and we see this as an honourable quest.
        </p>
        <p className={classes.paragragh}>
          The Empire is also against outside centralised entities leasing their land parcels and taking anything over 50% of the profits. There should be equality & harmony amongst businesses and consumers in Decentraland, without one profiteering from the other.
        </p>
        <p className={classes.paragragh}>
          The vEmpire DDAO will invade by continuously purchasing land in Decentraland until we have a solid and overwhelming presence. By only directing emissions to a MANA pool initially on the platform, it will give a substantial yield which we hope & would intend, to capture the majority of the market there could be for MANA and metaverse staking. The highest APY currently offered is 2%.
        </p>
        <p className={classes.paragragh_bold}>
          Decentraland (MANA) Interest Calculator and Current Rates | Staking Rewards
        </p>
        <p className={classes.paragragh_bold}>
          1.3 - The DDAO - Democratised decentralized autonomous organization
        </p>
        <p className={classes.paragragh}>
          A standard DAOs voting power is decided by the number of governance tokens the wallet that is voting holds rather than a democratic vote. This means that founders, developers and high net worth investors have an advantage over the many in a standard DAO. The Empire's soldiers have the same rights as the Empire’s officers & generals when it comes to democracy & governance, and so all wallets holding over $1,000 will have one vote, the only reason it is not $1 is bots. Having this lower threshold can lead to bots and so all votes will be reviewed for suspicious activity and to prevent Sybil Attacks.
          <br/><br/>
          The Empire rewards loyalty above all, loyalty comes with promotions & promotions come with rewards. As this is a DDAO and not a DAO, we reward based on time spent staked and not amount staked. Of course as above, this is open to abuse by bots & so the minimum amount of xVEMP needed to qualify for the ranks below is a monetary amount equating to $10,000. There are no ranges above this and promotions can still be earnt by smaller wallets but these will be from applications submitted and reviewed. Wearables and banners will be customised for the different levels.
        </p><br/>
        <p>
        <table className={classes.table1}>
          <tbody>
            <tr><td>Any amount of xVEMP Staked</td><td>Auxiliary</td> </tr>
            <tr><td>$10,000+ of xVEMP Staked for one year</td><td>Legion</td></tr>
            <tr><td>$10,000+ of xVEMP Staked for two years</td><td>Bannerman</td></tr>
            <tr><td>$10,000+ of xVEMP Staked for three years</td><td>Centurion</td></tr>
            <tr><td>$10,000+ of xVEMP Staked for five years</td><td>Officer</td></tr>
            <tr><td>$10,000+ of xVEMP Staked for ten years</td><td>General</td></tr>
          </tbody>
        </table>
        </p>
        <br/>
        <p className={classes.paragragh}>See section 5.6 for details on wearables & applications for promotions outside of the above criteria.
        </p>
        <p className={classes.paragragh_bold}>1.4 - Token distribution and protecting our Soldiers</p>
        <p className={classes.paragragh}>
          The founders will not sell tokens worth more in monetary value than their previous PAYE incomes earned prior to creation of the project, at any point in the first year of the project. The founders will sell no tokens at all unless the circulating supply is worth well in excess of 1 Million in the first year.
          <br/><br/>The founders have also agreed they will never sell tokens worth more than their previous incomes any year after that until the projects circulating supply is worth significantly more than 10 Million. This does not include token sales for development which will be decided by the DDAO upon completion of V1. The above agreement is only changed if the circulating supply of tokens hits 100M in market capitalisation where the limits are moved to percentages beginning at 1%.
          <br/><br/>Developers and early contributors will have 95% of their tokens locked for a minimum of two years, vesting in stages.
        </p>
        <p className={classes.paragragh_bold}>1.5 - VEMP Token airdrop</p>
        <p className={classes.paragragh}>The Empire has no quarrel with and loves the people of Decentraland, it is the greedy large centralised estate owners we wish to battle with. The Empire wants to show the people of Decentraland that we come in peace.
        <br/><br/>Existing users & landowners in decentraland will be able to claim wearables from www.v-empire.digital upon launch of the platform. All MANA holders will qualify for these wearables. As ever, the Empire rewards loyalty and those who claim and wear the empire's colours in Decentraland will qualify for the airdrop of VEMP.
        <br/><br/>For LAND owners to qualify you should add the VEMP logo & colours to your land and houses to show solidarity with the project and the Empires invasion.
        <br/><br/>The Empire can and will evolve through the ages with the buildings and units in Decentraland, effectively invading from the Iron Age and releasing NFTs and features in more recent ages as the Empire expands to new horizons. If relationships develop with other projects that support our cause then these too may be included for VEMP distribution.
        </p>
        <div className={classes.threedots}>
          <span className={classes.dot1}></span>
          <span className={classes.dot2}></span>
          <span className={classes.dot3}></span>
        </div>
        <p className={classes.paragragh_bold}>2.1 The Empire compounds APY within its strategies for consistent APY growth even with increased volume</p>
        <p className={classes.paragragh}>The Empires strategies are unique in that not only can the user compound APY by staking yield from one pool into another themselves, the strategies are also designed to naturally compound yield by reinvesting profits to buy more LAND. This means that the additional land monetized will grow the APY so that increased users of the strategy will not necessarily have a detrimental effect on the yield of the pools as it would in most other pools on DeFi DAOs.</p>
        <p className={classes.paragragh_bold}>2.2 - Incentivising MANA onto the platform</p>
        <p className={classes.paragragh}>The Empire’s initial conquest remains the metaverse, with the first voyage destined for Decentraland. The Empire plans to raise a warchest with the below strategy. This strategy, as always, rewards honour & loyalty by providing early stakers with yields in both VEMP and MANA.
        <br/><br/>The MANA staked is locked forever in the strategy and the contributors to the strategy are rewarded forever. The initial yield will be purely VEMP, incentivised by the emissions schedule. This will be paid for a set number of years whilst LAND is purchased continually and monetised using the stakers MANA. Once the land is providing an income, the strategy will yield both VEMP & MANA until the full supply of VEMP is circulating.
        <br/><br/>Eventually the emissions schedule will stop providing a yield to this strategy but by this time the LAND purchased will be vast & sufficiently monetized that the strategy will still remain an attractive place for soldiers to stake their MANA as they will still be rewarded with yield in MANA from all the Empire's territories.</p>

        
        <img className={classes.image_fluid} src={graphic_1} alt="img" />
        <p className={classes.paragragh_bold}>2.3 - LAND leasing, buying & maintenance of the DDAO</p>
        <p className={classes.paragragh}>The DDAOs multiple and initial revenue streams are illustrated below. As well as MANA, the VEMP staked onto the platform (xVEMP) will act as collateral in order to borrow from AAVE and others to stake tokens into off platform strategies that will pay yields higher than the APY paid on the funds borrowed. The profit made will be paid back to the DDAO & xVEMP stakers. The DDAO will also decide if a percentage will be converted to MANA to buy additional LAND for the Empire.
        <br/><br/>We will also lease LAND from property and estate owners for fixed term APYs, allowing fair and equal revenue streams from both Decentralands population, the MANA holders, and from Decentralands private landlords.
        <br/><br/>This land will be monetized and profits will be split, with 50% redistributed to xVEMP holders, and the other 50% being converted to MANA & used to purchase more land.</p>
        <img className={classes.image_fluid} src={graphic_2} alt="img" />
        <div className={classes.threedots}>
          <span className={classes.dot1}></span>
          <span className={classes.dot2}></span>
          <span className={classes.dot3}></span>
        </div>
        <p className={classes.paragragh_bold}> 3.1 - Metaverse limitations</p>
        <p className={classes.paragragh}>Multiple projects have suffered from a similar lack of imagination by applying real world games to a virtual world. The Empire will look to expand and bring games and monetization which can only be possible in the virtual world, alongside allowing access to real world favourites if desired.
          <br/><br/>The Empire has found other tokens and DAOs in this space suffer from a few limitations;
          <br/>- Barriers to entry. Governance votes where in some cases users need over $100,000 just to vote.
          <br/>- Rewards shared amongst DAOS. Other protocols in this space will also only allow those who partake in governance to benefit from rewards.
          <br/><br/>The Empires purpose however is to serve our loyal supporters, not to take advantage and reward those who have the biggest wallets. This is why governance proposals will be set at an acceptable monetary minimum which will start at $1000, rather than a percentage of tokens in distribution and all xVEMP owners will share in the rewards.
        </p>
       
        <p className={classes.paragragh_bold}>3.2 Vegas City, Metaverse Group/Ventures & Founders who won’t let go</p>
        <p className={classes.paragragh}>
        Metaverse Group & Decentraland have invested in Vegas City which is ok however the economy of Decentraland is changing with this and the Empire is seeing whales and centralised entities buying significant estates and leasing the land, keeping 70-80% of profits whilst the renters keep 20-30% The Flamingos — Vegas City.
        <br/><br/>Land scarcity combined with corporates are pricing individual land owners out of decentraland, with multiple districts owned by the same individuals. Our DDAO will fight for change amongst the population of Decentraland and seek to unite the land for a fairer and more prosperous future for all by implementing and advocating for limits on the amount of rental properties and rental income that can be gained from LAND in Decentraland.
        <br/><br/>The Empire's conquest is to save the population and property owners of the land from becoming a capitalist haven of advertising, centralised corporations and to prevent the commoditization of Decentralands users. Who will occupy the land when there is nothing but slot machines and adverts?</p>
        <p className={classes.paragragh_bold}>3.3 Development Schedule</p>
        <p className={classes.paragragh}>V1 Layer 1 - Completion of basic platform with NFTs launched on Opensea & ability to connect ETH wallets onto the website.
        <br/><br/>V1 Layer 2 - NFT boosts working & fully functioning trading card game launched on platform
        <br/><br/>V1 Layer 3 - This can coincide with Layer 4 if development is quick enough, Launch VEMP and airdrop to land owners who connect to the platform and advertise VEMP on their land/buildings. Wearables giveaway on platform for MANA holders who connect to the platform and equip their empire wearables in Decentraland.
        <br/><br/>V1 Layer 4 - Create the liquidity pools and the vEmpire DDAO.
        <br/><br/>V2 Layer 1 - Set/start the emissions schedule to incentivise MANA staking. The majority of emissions from the blocks will be given to the MANA pool, with diversification to follow into other strategies or projects the empire assesses.
        <br/><br/>V2 Layer 2 - LAND donations/leasing on and to the platform convert LAND to Empire aesthetics and begin the invasion of Decentraland using the MANA staked to buy land for the Empire’s DDAO.
        </p>
        <div className={classes.threedots}>
          <span className={classes.dot1}></span>
          <span className={classes.dot2}></span>
          <span className={classes.dot3}></span>
        </div>
        <p className={classes.paragragh_bold}>4.1 Tokenomics & emission schedule</p>
        <p className={classes.paragragh}>
        The Initial supply of VEMP will be 90M. In years one to three VEMP will be minted at 20 per block, reducing to 15 per block in years four to twelve, 10 per block in years thirteen to nineteen and 5 per block in years twenty and twenty one.
        <br/><br/>This equates to a total supply of 810,720,000 VEMP or 810,720m VEMP 
        </p>
        <p className={classes.paragragh}>
          X = Tokens Printed
        </p>
        <p className={classes.paragragh}>
          Y = Block Rewards
        </p>

        <p className={classes.paragragh}>Tokens minted per year - x=2.6208m*y  <b className={classes.minus}>-</b> <span className={classes.formula}>x=2.6208m*y</span></p>
        <img className={classes.image_fluid} src={graphic_3_1} alt="img" />
        <br/>
        <p className={classes.paragragh_bold}>4.2 Inflation table</p>
        <br/>
        <img className={classes.image_fluid} src={graphic_3_2} alt="img" />
        <div className={classes.threedots}>
          <span className={classes.dot1}></span>
          <span className={classes.dot2}></span>
          <span className={classes.dot3}></span>
        </div>
        <p className={classes.paragragh_bold}>5.1 - The Battles, The Arena & The Playing Card Game</p>
        <p className={classes.paragragh}>The Empire is coming to invade decentraland, but before that, the leader of the empire is to be decided between Romulus & Remus (5.4). Whoevers side has the most successful battles will have their colours and shields led into battle. Not all will have the courage for the Arena.
        <br/><br/>VEMP is staked for xVEMP. xVEMP can be staked/burnt, at a pre-agreed level e.g. a 10%, 25%, 50% which dictates the battle pool which will give the players xsVEMP. This is worth the same as xVEMP minus the percentage for the battle pool they have entered. To fight in The Arena is a great honour. To risk one's tokens for the advancement of the DDAO shows the true character of the soldiers, these brave gladiators will gain riches upon winning, but their losing counterparts' bravery will not go unnoticed by the DDAOs Emperors.
        <br/><br/>People in the Arenas pools can battle others for it and win tokens equivalent to their burn plus their opponents burn, minus a platform fee, which will be distributed to the DDAO (xVEMP holders). Example of a 50% burn pool battle -
        </p>
        <img className={classes.image_fluid} src={graphic_4} alt="img" />
        <p className={classes.paragragh_bold}>Discrete-time Markov Chain - xsVEMP Battle</p>
        <img className={classes.image_fluid} src={graphic_5} alt="img" />
        <p className={classes.paragragh}>Players entering the arena (xsVEMP holders) will automatically have their ETH address entered into a weekly/monthly VEMP airdrop lottery to further incentivise battle, for every loss incurred their address will be entered again into the airdrop lottery so losers will have an increased chance of recouping some of their loss. Losers will also earn VEMPcon (contributor) points which can be swapped for additional trading cards.</p>
        <p className={classes.paragragh_bold}>5.2 - The Empires Gladiators and the kings of men</p>
        <p className={classes.paragragh}>The Empire understands that entering into the arena is not without risk. To those with the bravery, every month those staked into xsVEMP and residing in the arena will have a chance to partake in a gladiator fight for all the DDAOs support. The prize will come from the DDAO and will not risk the soldiers' own funds. The soldiers will be chosen by the DDAO and will likely be those amongst us with the most fearless reputations.</p>
        <p className={classes.paragragh_bold}>5.3 - Fair Game</p>
        <p className={classes.paragragh}>
          As above, losers are given a trading card in exchange for their loss of xVEMP/xsVEMP. This will make it easier for the losers to win future battles, however the card cannot and will not be worth more than the loss as this would incentivise players to lose. The trading card given for a loss will not be an NFT, this way it gives them an advantage for the future but not a financial incentive.
          <br/><br/>
          xVEMP holders will earn a percentage of yield based on a 20% cut of xsVEMP burnt for battles between xsVEMP holders. xsVEMP holders will earn additional tokens from battles they win from opponents, but xsVEMP holders would also get xVEMP yield plus the additional yield/tokens from battles they partake in and win. However they can also lose in their fights.
          <br/><br/>Trading card game & battle will be built first (V1 layer 1) then V2 will be actioned post token, which will be the preparation and invasion of Decentraland. Depending on the method & results of the invasion, players will have the option to build houses, forts, towers or whatever they please with the potential to siege each other for rewards.
          <br/><br/>vEMPIRE is split into two Rival factions, Romulus & Remus. The DDAO will be a collection of both factions together. Players ETH addresses are automatically assigned their faction based on monthly voting power and battles won, this means that for 1 month, Romulus holders may have an advantage over Remus holders, and the next month it may be different. This adds token purchase incentives to maintain competitiveness and liquidity for the project, whilst making sure that one faction will not have control over voting for a long period to maintain a level playing field. Voting power is rebalanced by new entrants to the project being assigned to the faction with the lower voting power and/or active voters. Factions will have different unlockable features & NFTs as well as colour codes.
        </p>
        <p className={classes.paragragh_bold}>5.4 - Lore</p>
        <p className={classes.paragragh}>
          After years of quietly building military strength and governing the surrounding areas, the time has come for the Empire to expand its borders, and seek out new places to rule. The Empire has set its sights on Decentraland, where there is the opportunity to invade the land of others and place the population under its command. Using the efficient military training honed over time, and the passion of its people, the Empire will soon encompass everything in its path.
          <br/><br/>
          All is not as unified as it may seem however. After the peaceful reign of the former Emperor, his two sons vy for leadership of the powerful Empire. Romulus and Remus never saw eye to eye, and each brother won’t let the other get in the way of unlimited power.
          <br/><br/>Romulus is a strong leader of men, who wins the hearts of his people with responsibility, and promises of power and wealth.
          <br/>Remus is the true son of the wolf. He is sly and conniving, and he will use underhand tactics in order to gain the advantage in battle. He may not be as popular as Romulus, but he will not be bested easily when it comes to playing the long game.
          <br/><br/>Each brother comes with their own army, with unique abilities and troops at their disposal. Use different soldiers for different scenarios to overcome any advantage the opponent might think they have over you.
          <br/><br/>Take the all out attack approach, and throw strong troops at your opponent to win through brute force, or use your cunning, and outplay your opponent by strategically removing their key players and increasing the number of cards in your hand.
          <br/><br/>Choose a faction and back a brother to the end, seeing him to his rightful place as the new Emperor. Train your army, invest your time and resources into building an unstoppable force, and take what belongs to you.Then you will see your colours flown as far as the eye can see in the conquest of Decentraland and beyond.
        </p>
        <p className={classes.paragragh_bold}>5.5 - Trading Card ideas/themes</p>
        <p className={classes.paragragh}>
        Each faction has a finite deck, featuring a certain number of cards in total. There are multiples of every card except the named soldiers and Heroes. When playing a game, the player will choose a “Legion” of a total of 30 cards, and their hand is randomly assigned 15 cards from their Legion. All players will start with at least one of every regular card, plus a basic version of the faction leader. Boosts are available by purchasing them as NFTs, this will increase damage/abilities by a certain percentage, as well as give strong perks to apply as an advantage in battle.
        <br/><br/>Unique soldier cards can only be purchased as NFTs, as well as higher level “shiny” versions that have stronger perks and higher strength, as well as giving the player bragging rights.
        <br/><br/>Players can purchase packs of a certain amount of cards which guarantees them at least one shiny or unique card.
        </p>
        <p className={classes.paragragh_bold}>5.6 - Wearables & No Soldier Left Behind</p>
        <p className={classes.paragragh}>Upon promotion through the Empire’s ranks soldiers will receive a wearable reflecting their achievement and loyalty. This standard armor can be upgraded, collected & changed at will. Armor, from any rank, will be available to purchase, however for every rank below a soldier is to the wearable/armor they would like to purchase, the premium increases by 100%.
        <br/><br/>For example, a Bannerman purchases a rare item of Armor in their rank. This costs them 0.5 ETH or VEMP equivalent. A member of the Legion can also purchase this same armor, however this will cost them 1 ETH or VEMP equivalent. This stacks the higher somebody tries to buy outside of their rank.
        <br/><br/>In the spirit of fair game and equality among all, as mentioned in section 1.3, the Empire is a DDAO and so democracy rules free. Monetary limitations are only enforced where bots that would do us harm could influence and corrupt our new democratic society & equal voting system.
        <br/><br/>The Empire rewards loyalty above all, and loyalty will never just come from those with money, the Empire is built by the people for the people and so we will hear applications for increases in rank from any soldier who believes they have been</p>
        <p className={classes.paragragh_bold}>Card Proof of Concept</p>
        <br/>
        <div  className={classes.table_responsive}>
          <table className={classes.table2}>
          <tbody>
          <tr>
            <th colSpan="4" className={classes.border_bottom_right}>
              Romulus Troops
            </th>
            <th colSpan="4" className={classes.border_bottom}>
              Remus Troops
            </th>
          </tr>
          <tr>
            <th className={classes.border_bottom}>
            Name
            </th>
            <th className={classes.border_bottom}>
            Strength
            </th>
            <th className={classes.border_bottom}>
            Class
            </th>
            <th className={classes.border_bottom_right}>
            Ability
            </th>
            <th className={classes.border_bottom}>
            Name
            </th>
            <th className={classes.border_bottom}>
            Strength
            </th>
            <th className={classes.border_bottom}>
            Class
            </th>
            <th className={classes.border_bottom}>
            Ability
            </th>
          </tr>
          <tr>
            <td className={classes.border_bottom_right}>Barbarians</td>
            <td className={classes.border_bottom_right}>1</td>
            <td className={classes.border_bottom_right}>Light Soldier</td>
            <td className={classes.border_bottom_right}><b>Wild Animals:</b> Remove 1 Strength from your opponent’s Light Soldier class for each Barbarian in play.</td>
            <td className={classes.border_bottom_right}>Villagers</td>
            <td className={classes.border_bottom_right}>1</td>
            <td className={classes.border_bottom_right}>Light Soldier</td>
            <td className={classes.border_bottom}><b>No ability.</b></td>
          </tr>
          <tr>
            <td className={classes.border_bottom_right}>Light Infantry</td>
            <td className={classes.border_bottom_right}>1</td>
            <td className={classes.border_bottom_right}>Light Soldier</td>
            <td className={classes.border_bottom_right}><b>Morale Boost:</b> For every light infantry card in play, double their strength</td>
            <td className={classes.border_bottom_right}>Auxiliary</td>
            <td className={classes.border_bottom_right}>2</td>
            <td className={classes.border_bottom_right}>Light Soldier</td>
            <td className={classes.border_bottom}><b>Expendable:</b> When an Auxiliary card is burned or discarded, you can replace it with a previously discarded Auxiliary once per round.</td>
          </tr>
          <tr>
            <td className={classes.border_bottom_right}>Triarii</td>
            <td className={classes.border_bottom_right}>2</td>
            <td className={classes.border_bottom_right}>Light Soldier</td>
            <td className={classes.border_bottom_right}><b>No ability.</b></td>
            <td className={classes.border_bottom_right}>Hastati</td>
            <td className={classes.border_bottom_right}>2</td>
            <td className={classes.border_bottom_right}>Light Soldier</td>
            <td className={classes.border_bottom}><b>No ability.</b></td>
          </tr>
          <tr>
            <td className={classes.border_bottom_right}>Archer</td>
            <td className={classes.border_bottom_right}>3</td>
            <td className={classes.border_bottom_right}>Ranged</td>
            <td className={classes.border_bottom_right}><b>Volley:</b> When you play the archer card, all other archers from your hand get played automaticall y.</td>
            <td className={classes.border_bottom_right}>Crossbowm an</td>
            <td className={classes.border_bottom_right}>3</td>
            <td className={classes.border_bottom_right}>Ranged</td>
            <td className={classes.border_bottom}><b>Efficiency:</b> add 1 strength for each enemy infantry card. Doesn’t stack.</td>
          </tr>
          <tr>
            <td className={classes.border_bottom_right}>Javelins</td>
            <td className={classes.border_bottom_right}>3</td>
            <td className={classes.border_bottom_right}>Ranged</td>
            <td className={classes.border_bottom_right}><b>Pila:</b> When you play a javelin card, remove an opponent's card of your choice, lower or equal to the strength of 3. Javelin card is then burned and removed from play.</td>
            <td className={classes.border_bottom_right}>Slingers</td>
            <td className={classes.border_bottom_right}>2</td>
            <td className={classes.border_bottom_right}>Ranged</td>
            <td className={classes.border_bottom}><b>A Stone’s Throw:</b> Remove the weakest enemy card and put it into their discard pile.</td>
          </tr>
          <tr>
            <td className={classes.border_bottom_right}>Mounted Archer</td>
            <td className={classes.border_bottom_right}>2</td>
            <td className={classes.border_bottom_right}>Ranged</td>
            <td className={classes.border_bottom_right}><b>No ability.</b></td>
            <td className={classes.border_bottom_right}>Legionnaire</td>
            <td className={classes.border_bottom_right}>3</td>
            <td className={classes.border_bottom_right}>Heavy Soldier</td>
            <td className={classes.border_bottom}><b>The Machine:</b>Double strength for each other legionnaire card in play.</td>
          </tr>
          <tr>
            <td className={classes.border_bottom_right}>Principes</td>
            <td className={classes.border_bottom_right}>4</td>
            <td className={classes.border_bottom_right}>Heavy Soldier</td>
            <td className={classes.border_bottom_right}><b>No ability.</b></td>
            <td className={classes.border_bottom_right}>Mercenary</td>
            <td className={classes.border_bottom_right}>4</td>
            <td className={classes.border_bottom_right}>Heavy Soldier</td>
            <td className={classes.border_bottom}><b>Bought and Paid For:</b> If still active after winning a round, the card remains on the playing board during the following round.</td>
          </tr>
          <tr>
            <td className={classes.border_bottom_right}>Tortuga</td>
            <td className={classes.border_bottom_right}>4</td>
            <td className={classes.border_bottom_right}>Heavy Soldier</td>
            <td className={classes.border_bottom_right}><b>Impenetrab le Fortress:</b> Tortuga are ignored by any card that would either burn or discard them.</td>
            <td className={classes.border_bottom_right}>Speculatore</td>
            <td className={classes.border_bottom_right}>2</td>
            <td className={classes.border_bottom_right}>Scout</td>
            <td className={classes.border_bottom}><b>Eagle Vision:</b> Choose a card from your discard pile and put it back in your hand.</td>
          </tr>
          <tr>
            <td className={classes.border_bottom_right}>Cavalry Scout</td>
            <td className={classes.border_bottom_right}>2</td>
            <td className={classes.border_bottom_right}>Scout</td>
            <td className={classes.border_bottom_right}><b>Eyes on the Prize:</b> Choose two cards from your opponent's hand and look at them.</td>
            <td className={classes.border_bottom_right}>Equites</td>
            <td className={classes.border_bottom_right}>3</td>
            <td className={classes.border_bottom_right}>Mounted Troop</td>
            <td className={classes.border_bottom}><b>No ability.</b></td>
          </tr>
          <tr>
            <td className={classes.border_bottom_right}>Heavy Cavalry</td>
            <td className={classes.border_bottom_right}>5</td>
            <td className={classes.border_bottom_right}>Mounted Troop</td>
            <td className={classes.border_bottom_right}><b>Rout:</b> Half the strength of all Auxiliary or light infantry cards.</td>
            <td className={classes.border_bottom_right}>Cataphract</td>
            <td className={classes.border_bottom_right}>6</td>
            <td className={classes.border_bottom_right}>Mounted Troop</td>
            <td className={classes.border_bottom}><b>No ability.</b></td>
          </tr>
          <tr>
            <td className={classes.border_bottom_right}>Aristocrat</td>
            <td className={classes.border_bottom_right}>0</td>
            <td className={classes.border_bottom_right}>Politician</td>
            <td className={classes.border_bottom_right}><b>Diplomat:</b> Pick a card out of your deck and put it in your hand. Your opponent may see the chosen card.</td>
            <td className={classes.border_bottom_right}>Magistrate</td>
            <td className={classes.border_bottom_right}>0</td>
            <td className={classes.border_bottom_right}>Politician</td>
            <td className={classes.border_bottom}><b>Swaying the Crowd:</b> Choose an opponent’s card at random from their hand and they must play that card on their next turn. You cannot look at the cards.</td>
          </tr>
          <tr>
            <td className={classes.border_bottom_right}>The Senate</td>
            <td className={classes.border_bottom_right}>0</td>
            <td className={classes.border_bottom_right}>Politician</td>
            <td className={classes.border_bottom_right}><b>Man of the People:</b> Play on top of any other card/group of the same card to double their overall strength.</td>
            <td className={classes.border_bottom_right}>Imperator</td>
            <td className={classes.border_bottom_right}>0</td>
            <td className={classes.border_bottom_right}>Politician</td>
            <td className={classes.border_bottom}><b>Ruthless Tactics:</b> Choose a class, and halve the overall strength of that class for your opponent</td>
          </tr>
          <tr>
            <td className={classes.border_right}>Hero: Romulus</td>
            <td className={classes.border_right}>10</td>
            <td className={classes.border_right}>Hero</td>
            <td className={classes.border_right}><b>Our Fearless Leader:</b> Impervious to all status effects and abilities.</td>
            <td className={classes.border_right}>Hero: Remus</td>
            <td className={classes.border_right}>10</td>
            <td className={classes.border_right}>Hero</td>
            <td><b>Son of the Wolf:</b> Choose any card on your opponent’s board and burn it.</td>
          </tr>
          </tbody>
        </table>
        </div>
        <div className={classes.threedots}>
          <span className={classes.dot1}></span>
          <span className={classes.dot2}></span>
          <span className={classes.dot3}></span>
        </div>
        <p className={classes.paragragh_bold}> 6.0 - Disclaimer</p>
        <p  className={classes.paragragh}>
          This paper is for general information purposes only. It does not constitute investment advice or a recommendation or solicitation to buy or sell any investment or currency and should not be used in the evaluation of the merits of making any investment decision. It should not be relied upon for accounting, legal or tax advice or investment recommendations. This paper reflects current opinions of the authors and is not made on behalf of or its affiliates and does not necessarily reflect the opinions of vEmpire, its affiliates or individuals associated with vEmpire. The opinions reflected herein are subject to change without being updated.
        </p>
        <br/><br/>
      </div>

      
      <Footer/>
     
    </div>
  );
};
