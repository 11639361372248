import { Hidden } from "@material-ui/core"
import vEmpire_logo_large from "../../images/vEmpire_logo_large.jpeg";
import logos from "../../images/logos.png";

const homePageStyles = {
	root: {
		// minWidth: "768px",
		color: "rgb(255, 255, 255)",
		position: "relative",
	},
	black_content: {
		backgroundColor: "#000 !important",
		// backgroundImage: `url(${vEmpire_logo_large}) !important`,
		// backgroundRepeat: "no-repeat !important",
		// backgroundSize: "cover",
		// backgroundPosition: "center center",
		position: "relative",
		overflow: "hidden",
		paddingTop: "30px",
		"@media (max-width: 738px)": {
			paddingTop: "10px",
		}
	},
	footer_unicrypt:{
		padding: "10px 0",
		textAlign: "center",
		marginTop: "30px",
		"@media (min-width: 992px)": {
			position: "absolute",
			left: "50%",
			transform: "translateX(-50%)",
		}
	},
	
	footer_text:{
		fontFamily: "Poppins",
		fontSize: "15px",
		display: "inline-block",
		width: "151px",
		verticalAlign: "super",
		marginLeft: "10px",
		marginRight: "15px",
		color: "#000",
		textAlign: "left",
		fontWeight: "bold"
	},
	footer_logo:{
		width: "60px"
	},
	featured_parent:{
		position: "relative",
		maxWidth: "1416px",
		margin: "auto",
	}, 
	featured:{
		display: "flex",
		justifyContent: "space-around",
		alignItems: "center",
		flexWrap: "wrap"
	},
	featured_item:{
		"@media (max-width: 992px)": {
			flex: "0 0 50%",
			maxWidth: "50%",
			textAlign: "center"
		}
	},
	featured_logo:{
		width: "110px",
		marginBottom: "1rem"
	},
	participate:{
		textAlign: "center",
		maxWidth: "1416px",
		margin: "10px auto 0",
		padding: "0 15px",
		display: "none",
		position: "relative",
		"@media (max-width: 738px)": {
			textAlign: "center",
			display: "block",
		}
	},
	participate_a:{
		color: "#fff",
		background: "#141414",
		margin: "0px auto ",
		display: "inline-block",
		fontSize: "20px",
		fontWeight: "600",
		fontFamily: "Poppins",
		border: "2px solid #eee",
		padding: "32.5px 50px",
		borderRadius: "15px",
		textDecoration: "none",
		width: "323px",
		margin: "0 0.5px 10px",
		maxWidth: "100%",
		"@media (max-width: 738px)": {
			fontSize: "13px",
			padding: "16px 20px",
			width: "215px",
		},
		"@media (max-width: 440px)": {
			fontSize: "13px",
			padding: "12.5px 20px",
			width: "initial",
		}
	},
	// image_content: {
	// 	// backgroundImage: `url(${vEmpire_logo_large}) !important`,
	// 	// borderStyle: "solid !important",
	// 	// borderWidth: "0 !important",
	// 	minHeight: "736px",
	// 	// backgroundRepeat: "no-repeat !important",
	// 	// backgroundSize: "contain !important",
	// 	// backgroundPosition: "50% 50%!important",
	// 	width: "auto !important",
	// 	maxWidth: "60% !important",
	// 	margin: "0px auto 50px !important",
	// 	minWidth: "0 !important",
	// 	textAlign: "start !important",
	// 	overflow: "hidden",
	// 	"@media (min-width: 1200px)": {
	// 		transform: "translateY(-64px)"
	// 	},
	// 	"@media (max-width: 738px)": {
	// 		backgroundImage: `url(${vEmpire_logo_large}) !important`,
	// 		backgroundPosition: "50% 50%!important",
	// 		backgroundSize: "contain !important",
	// 		backgroundRepeat: "no-repeat !important",
	// 		maxWidth: "100% !important",
	// 		minHeight: "100vw",
	// 	}
	// },
	image_content: {
		backgroundImage: `url(${vEmpire_logo_large}) !important`,
		borderStyle: "solid !important",
		borderWidth: "0 !important",
		minHeight: "600px",
		backgroundRepeat: "no-repeat !important",
		backgroundSize: "contain !important",
		backgroundPosition: "50% 50%!important",
		width: "auto !important",
		maxWidth: "90% !important",
		margin: "10px auto 20px !important",
		minWidth: "0 !important",
		textAlign: "start !important",
		"@media (max-width: 738px)": {
			margin: "30px auto 20px !important",
			minHeight: "70vw",
		}
	},
	video:{
		width: "100% !important",
		transform: "scale(1.5)",
		position: "absolute",
		left: "50%",
		top: "calc(50% - 150px)",
		transform: "translate(-50%, -50%)",
		"@media (max-width: 738px)": {
			display: "none"
		}
	},
	video_mobile:{
		width: "100% !important",
		position: "absolute",
		left: "0%",
		top: "calc(50% - 150px)",
		transform: "scale(3)",
		display: "none",
		"@media (max-width: 738px)": {
			display: "block",
		}
	},
	texts:{
		textAlign: "center",
		fontSize: "20px",
		fontWeight: "500",
		fontFamily: "Poppins",
		marginBottom: "4rem",
	},
	link:{
		color: "#46ffd1",
		fontWeight: "600",
		fontSize: "24px",
	},
	requirements:{
		fontSize: "16px",
		fontWeight: "500",
		marginBottom: "2rem",
		padding: "0 1rem"
	},
	unc_logo:{
		width: "30px",
		verticalAlign: "bottom"
	},
	text_content: {
		background: "#474747",
		textAlign: "center",
		padding: "16.5px 40px",
		position: "relative"
	},
	text_h2: {
		fontSize: "30px",
		lineHeight: "initial",
		textTransform: "uppercase",
		margin: "8px 0!important",
	},
	text_span: {
		fontFamily: "Cinzel",
		display: "initial",
		fontWeight: 400,
	},
	form_section:{
		"@media (max-width: 738px)": {
			textAlign: "center !important",
			padding: "0 20px",
		}
	},
	textAlignRight: {
		textAlign: "right",
	},
	recaptcha: {
		position: "absolute",
		bottom: "10px",
		left: "0px",
	},
	dialogWrapper: {},
}

export default homePageStyles
