import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import TopLink from "../../components/TopLink";

import integrationsAndPartnershipsPageStyles from "../../assets/js/pages/integrationsAndPartnershipsPageStyles"
import down from "../../assets/images/down.svg";
import panteraico from "../../assets/images/panteraico.jpg";
import polygon from "../../assets/images/polygon-logo.svg";
import chainlink from "../../assets/images/chainlink.jpg";
import akg from "../../assets/images/akg.jpg";
import moonwhale from "../../assets/images/moonwhale.png";
import carbonblue from "../../assets/images/carbonblue.png";
import oddiyana from "../../assets/images/oddiyana-ventures.jpg";
import mexcGlobal from "../../assets/images/mexc-global.jpg";
import primeblock from "../../assets/images/primeblock.png";


const useStyles = makeStyles(integrationsAndPartnershipsPageStyles);

export const IntegrationsAndPartnerships = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
        {/* <TopLink/> */}
      <div className={classes.black_content}>
      <Header/>
        
        <div className={classes.text_content}>
          <h2 className={classes.text_h2}>
            <span className={classes.text_span}>Integrations and Partnerships</span>
          </h2>
        </div>
      </div>
      <div className={classes.logos}>
        <ul className={classes.logos_ul}>
        <li className={classes.logos_li}>
              <a href="https://chain.link/" className={classes.logos_a}>
                <img className={classes.logos_img} src={chainlink} alt="img" />
              </a>
          </li>
          <li className={classes.logos_li}>
              <a href="https://www.mexc.com/" className={classes.logos_a}>
                <img className={classes.logos_img} src={mexcGlobal} alt="img" />
              </a>
          </li>
          <li className={classes.logos_li}>
              <a href="https://polygon.technology/" className={classes.logos_a}>
                <img className={classes.logos_img} src={polygon} alt="img" />
              </a>
          </li>
          <li className={classes.logos_li}>
              <a href="https://oddiyana.ventures/" className={classes.logos_a}>
                <img className={classes.logos_img} src={oddiyana} alt="img" />
              </a>
          </li>
          <li className={classes.logos_li}>
              <a href="http://www.akgvc.com/" className={classes.logos_a}>
                <img className={classes.logos_img} src={akg} alt="img" />
              </a>
          </li>
          <li className={classes.logos_li}>
              <a href="https://moonwhale.ventures/" className={classes.logos_a}>
                <img className={classes.logos_img} src={moonwhale} alt="img" />
              </a>
          </li>
          <li className={classes.logos_li}>
              <a href="https://www.carbon.blue/" className={classes.logos_a}>
                <img className={classes.logos_img} src={carbonblue} alt="img" />
              </a>
          </li>
          <li className={classes.logos_li}>
              <a href="https://twitter.com/panteraico" className={classes.logos_a}>
                <img className={classes.logos_img} src={panteraico} alt="img" />
              </a>
          </li>
          <li className={classes.logos_li}>
              <a href="http://www.primeblock.ventures/" className={classes.logos_a}>
                <img className={classes.logos_img} src={primeblock} alt="img" />
              </a>
          </li>
        </ul>
      </div>
      <br/><br/><br/><br/>
      <Footer/>
     
    </div>
  );
};
