import React, { useEffect, useState, useRef } from 'react'
import { makeStyles } from "@material-ui/core/styles"

import TopLinkStyles from "../assets/js/components/TopLinkStyles";
import close from "../assets/images/close.svg";


const useStyles = makeStyles(TopLinkStyles)
function TopLink(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    useEffect(() => {
      if(!sessionStorage.closeLink || sessionStorage.closeLink ==  'true'){
        setOpen(true);
      }
    })
    const closeLink = () => {
      sessionStorage.setItem('closeLink', false)
        setOpen(false);
    };
    return (
        <>
          <div className={`${classes.top_link} ` + (open ? classes.show : '' )}>
            <a  className={classes.top_link_a}>
              Warning! Double check all links before sending funds, scammers are known to operate & impersonate Unicrypt to steal funds
            </a>
            
            <img className={classes.close_icon} src={close} alt="img"  onClick={closeLink}  />
          </div>
       
         </>
        
    )
}

export default TopLink
