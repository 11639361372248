import { Hidden } from "@material-ui/core"
import vEmpire_logo_large from "../../images/vEmpire_logo_large.jpeg";

const whatisnftPageStyles = {
	root: {
		// minWidth: "768px",
		color: "rgb(255, 255, 255)",
		position: "relative",
	},
	black_content: {
		backgroundColor: "#000 !important",
		paddingTop: "30px",
		"@media (max-width: 738px)": {
			paddingTop: "10px",
		}
	},
	footer_text:{
		fontFamily: "Poppins",
		fontSize: "15px",
		display: "inline-block",
		width: "151px",
		verticalAlign: "super",
		marginLeft: "10px",
		marginRight: "15px",
		color: "#000",
		textAlign: "left",
		fontWeight: "bold"
	},
	text_content: {
		background: "#474747",
		textAlign: "center",
		padding: "16.5px 40px",
		marginTop: "100px",
		"@media (max-width: 738px)": {
			marginTop: "0"
		}
	},
	text_h2: {
		fontSize: "30px",
		lineHeight: "initial",
		textTransform: "uppercase",
		margin: "8px 0!important",
	},
	text_span: {
		fontFamily: "Cinzel",
		display: "initial",
		fontWeight: 400,
	},
	recaptcha: {
		position: "absolute",
		bottom: "10px",
		left: "0px",
	},
	whitepaper_container: {
		margin: "auto",
		maxWidth: "1060px",
		color: "#000",
		padding: "0 15px"
	},
	paragragh: {
		fontSize: "18px",
		fontWeight: "500",
		fontFamily: "Poppins",
		marginBottom: "1.8rem"
	},
	paragragh_intend: {
		fontSize: "18px",
		fontWeight: "500",
		fontFamily: "Poppins",
		marginBottom: "1.8rem",
		marginLeft: "62px",
		"@media (max-width: 738px)": {
			marginLeft: "12px",
		}
	},
	paragragh_intend_large: {
		fontSize: "18px",
		fontWeight: "500",
		fontFamily: "Poppins",
		marginBottom: "1.8rem",
		marginLeft: "106px",
		"@media (max-width: 738px)": {
			marginLeft: "12px",
		}
	},
	paragragh_bold: {
		fontSize: "18px",
		fontFamily: "Poppins",
		fontWeight: 700
	},
	by:{
		fontSize: "18px",
		fontFamily: "Poppins",
		fontWeight: 600,
		marginBottom: "10px",
	},
	line:{
		width: "62px",
		height: "3px",
		background: "#000",
		display: "inline-block",
		transform: "translateY(5px)"
	},
	link: {
		fontSize: "18px",
		fontWeight: "600",
		color: "#474747",
		fontFamily: "Poppins",
	},
	font_size_small:{
		fontSize: "21px"
	},
	dialogWrapper: {},
}

export default whatisnftPageStyles
