import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";

import ConnectWalletButton from "../../components/ConnectWalletButton";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import TopLink from "../../components/TopLink";

import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";
import faqPageStyles from "../../assets/js/pages/faqPageStyles"
import graphic_1 from "../../assets/images/graphic_1.svg";
import graphic_2 from "../../assets/images/graphic_2.svg";
import graphic_3_1 from "../../assets/images/graphic_3.1.svg";
import graphic_3_2 from "../../assets/images/graphic_3.2.svg";
import graphic_4 from "../../assets/images/graphic_4.svg";
import graphic_5 from "../../assets/images/graphic_5.svg";
import down from "../../assets/images/down.svg";
import mailto from "../../assets/images/mail-at-white.svg";
import twitter from "../../assets/images/twitter-white.svg";
import instagram from "../../assets/images/instagram-white.svg";
import telegram from "../../assets/images/telegram-white.svg";
import medium from "../../assets/images/medium-white.svg";
import linkedin from "../../assets/images/linkedin-white.svg";
import youtube from "../../assets/images/youtube-white.svg";
import axios from "axios";
const useStyles = makeStyles(faqPageStyles);

export const Faq = () => {
  const classes = useStyles();
  
  const refName = useRef();
  const refEmail = useRef();
  const refMessage = useRef();
  const [emailSent, setEmailSent] = useState(false)

  const handleEmailFormClick = async (event) => {
    event.preventDefault();
    const apiUrl = 'https://api.v-empire.digital/api/v1/wallets/sendFaqMessage';
    try {
    let response = await axios.post(apiUrl, { from: refEmail.current.value, content: refName.current.value, content: refMessage.current.value});
    setEmailSent(true)
    document.querySelector('form').reset()
    setTimeout(()=>{
        setEmailSent(false)
    }, 4000)
    } catch (error) {
    console.log(error);
    }
  };
  return (
    <div className={classes.root}>
        {/* <TopLink/> */}
      <div className={classes.black_content}>
        <Header/>
        <div className={classes.text_content}>
          <h2 className={classes.text_h2}>
            <span className={classes.text_span}>Faq</span>
          </h2>
        </div>
      </div>
      <div className={classes.contact_content}>
          <ul className={classes.contact_content_ul}>
            <li className={classes.contact_content_li}>
              <Link to="/what-is-nft" className={classes.contact_content_a}>What is an NFT?</Link>
            </li>
            <li className={classes.contact_content_li}>
              <Link to="/how-to-participate-in-the-vempire-public-sale" className={classes.contact_content_a}>How to participate in the <br/>vEmpire public sale</Link>
            </li>
            <li className={classes.contact_content_li}>
              <Link to="/what-is-virtual-real-estate-&-what-makes-it-valuable" className={classes.contact_content_a}>What Is Virtual Real Estate <br/>& What Makes It Valuable?</Link>
            </li>
          </ul>
          <div className={classes.contact_form_content}>
            <span className={classes.contact_title}>Email us your questions!</span>
            <form>
              <div className={classes.form_group}>
                <label  className={classes.form_group_label}>
                Name
                </label>
                <input className={classes.form_group_input} type="text" placeholder="Name" ref={refName}/>
              </div>
              <div className={classes.form_group}>
                <label  className={classes.form_group_label}>
                Email address
                </label>
                <input className={classes.form_group_input} type="text" placeholder="Email address" ref={refEmail} />
              </div>
              <div className={classes.form_group}>
                <label  className={classes.form_group_label}>
                Your queries
                </label>
                <textarea className={classes.form_group_textarea} placeholder="Message" ref={refMessage}></textarea>
              </div>
              <button className={`${classes.form_submit} ` + (emailSent ? classes.form_submited : '')} onClick={handleEmailFormClick}>
              { emailSent ? 'Email Sent' : 'SUBMIT'} 
              </button>
            </form>
          </div>
      </div>
        
      <Footer/>
     
    </div>
  );
};
