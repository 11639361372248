import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";

import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import TopLink from "../../components/TopLink";
import contactPageStyles from "../../assets/js/pages/contactPageStyles"
import axios from "axios";



const useStyles = makeStyles(contactPageStyles);

export const Contact = () => {
  const classes = useStyles();

  const refName = useRef();
  const refEmail = useRef();
  const refMessage = useRef();
  const [emailSent, setEmailSent] = useState(false)

  const handleEmailFormClick = async (event) => {
    event.preventDefault();
    const apiUrl = 'https://api.v-empire.digital/api/v1/wallets/sendContactMessage';
    try {
    let response = await axios.post(apiUrl, { from: refEmail.current.value, content: refName.current.value, content: refMessage.current.value});
    setEmailSent(true)
    document.querySelector('form').reset()
    setTimeout(()=>{
        setEmailSent(false)
    }, 4000)
    } catch (error) {
    console.log(error);
    }
  };
  return (
    
    <div className={classes.root}>
       {/* <TopLink/> */}
      <div className={classes.black_content}>
        <Header/>
        <div className={classes.contact_content}>
          <ul className={classes.contact_content_ul}>
            <li className={classes.contact_content_li}>
              <Link to="/faq" className={classes.contact_content_a}>FAQ</Link>
            </li>
            <li className={classes.contact_content_li}>
              <Link to="/whitepaper" className={classes.contact_content_a}>WHITEPAPER</Link>
            </li>
            <li className={classes.contact_content_li}>
              <Link to="/unicrypt" className={classes.contact_content_a}>UNICRYPT</Link>
            </li>
            <li className={classes.contact_content_li}>
              <Link to="/team" className={classes.contact_content_a}>OUR TEAM</Link>
            </li>
            <li className={classes.contact_content_li}>
              <Link to="/mission" className={classes.contact_content_a}>vEMPIRE’S MISSION</Link>
            </li>
          </ul>
          <div className={classes.contact_form_content}>
            <span className={classes.contact_title}>Contact</span>
            <form>
              <div className={classes.form_group}>
                <label  className={classes.form_group_label}>
                Name
                </label>
                <input className={classes.form_group_input} type="text" placeholder="Name" ref={refName} />
              </div>
              <div className={classes.form_group}>
                <label  className={classes.form_group_label}>
                Email address
                </label>
                <input className={classes.form_group_input} type="text" placeholder="Email address" ref={refEmail} />
              </div>
              <div className={classes.form_group}>
                <label  className={classes.form_group_label}>
                Your queries
                </label>
                <textarea className={classes.form_group_textarea} placeholder="Message" ref={refMessage} ></textarea>
              </div>
              <button className={`${classes.form_submit} ` + (emailSent ? classes.form_submited : '')} onClick={handleEmailFormClick}>
                { emailSent ? 'Email Sent' : 'SUBMIT'} 
              </button>
            </form>
          </div>
        </div>
        <div className={classes.text_content}>
          <h2 className={classes.text_h2}>
            <span className={classes.text_span}>The Front line in the fight against centralisation</span>
          </h2>
        </div>
      </div>
      
      <Footer/>
     
    </div>
  );
};
