const HeaderStyles = {
    overlay:{
		display: "none"
	},
    overlay_show:{
		width: "100%",
		height: "100%",
		position: "fixed",
		left: "0",
		top: "0",
		display: "block"

	},
	mobile_menu:{
		display: "none"
	},
	mobile_menu_show:{
		position: "absolute",
		background: "#fff",
		top: "90px",
		display: "block",
		padding: "10px 0"
	},
	header_unicrypt_mobile:{
		margin: "0px 10px 0 0",
		display: "flex",
    	alignItems: "center"
	},
	header_unicrypt_text:{
		fontFamily: "Poppins",
		fontSize: "12px",
		display: "inline-block",
		width: "121px",
		verticalAlign: "super",
		marginRight: "15px",
		color: "#fff",
		textAlign: "left",
		fontWeight: "bold",
		"@media (max-width: 375px)": {
			width: "111px",
			display: "block",
			fontSize: "11px",
		}
	},
	header_unicrypt_logo:{
		width: "27px"
	},
	header_logo:{
		width: "50px"
	},
	opensea_logo:{
		width: "123px",
		marginTop: "6px",
		"@media (max-width: 375px)": {
			width: "123px",
		}
	},
	mobile_menu_link:{
		color: "#000 !important",
		margin: "0 15px",
		display: "inline-block",
		fontSize: "16px",
		fontFamily: "Cinzel",
		minWidth: "82px",
		fontWeight: "600",
		"@media (max-width: 738px)": {
			display: "block",
		}
	},
	
	mobile_menu_link_sub:{
		color: "#000 !important",
		margin: "0 15px",
		display: "inline-block",
		fontSize: "16px",
		fontFamily: "Cinzel",
		position: "relative",
		padding: "16px 0",
		fontWeight: "600",
		marginBottom: "5px",
    	display: "block",
		"@media (max-width: 880px)": {
			padding: "0",
		}
	},
	mobile_menu_link_sub_li:{
		listStyle: "none",
		marginLeft: "-15px",
		marginBottom: "6px",
		marginTop: "4px",
	},
	menu:{
		display: "none",
		"@media (max-width: 880px)": {
			display: "block",
		}
	},
	menu_icon:{
		cursor: "pointer",
		marginTop: "21px"
	},
	sub_header:{
		display: "none",
		padding: "0 15px",
		justifyContent: "space-between",
		"@media (max-width: 738px)": {
			display: "flex",
		}
	},
	header:{
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		maxWidth: "1416px",
    	margin: "auto",
		padding:" 0 15px",
		marginBottom: "1rem",
		position: "relative",
		zIndex: "10",
		"@media (max-width: 738px)": {
			marginBottom: "30px",
		}
	},
	left_section:{
		"@media (max-width: 1200px)": {
			display: "none",
		}
	},
	v_empire:{
		fontSize: "23px",
		fontFamily: "Cinzel",
	},
	mexc_mobile:{
		display: "flex",
		alignItems: "center",
		fontFamily: "Poppins",
		fontSize: "13px",
		width: "145px",
		verticalAlign: "super",
		color: "#fff",
		textAlign: "left",
		fontWeight: "bold",
	},
	mexc:{
		display: "flex",
		alignItems: "center",
		fontFamily: "Poppins",
		fontSize: "13px",
		width: "145px",
		verticalAlign: "super",
		color: "#fff",
		textAlign: "left",
		fontWeight: "bold",
		"@media (max-width: 738px)": {
			display: "none",
		}
	},
	mexc_mobile_logo:{
		width: "40px",
		marginLeft: "10px"
	},
	mexc_logo:{
		width: "60px",
		marginLeft: "10px"
	},
	header_socials:{
		display: "flex",
		"@media (max-width: 738px)": {
			display: "none",
		}
	},
	header_socials_a:{
	},
	header_socials_link:{
		display: "block",
		textAlign: "center",
		margin: "0 5px"
	},
	header_social_image:{
		maxWidth: "22px",
		verticalAlign: "bottom"

	},
	
	menu_link:{
		color: "#fff !important",
		margin: "0 11px",
		display: "inline-block",
		fontSize: "16px",
		fontFamily: "Cinzel",
		whiteSpace: "pre",
		padding: "6px 0px 0",
		"&:hover": {
			color: "#f4df78 !important"
		},
		"@media (max-width: 738px)": {
			display: "block",
		}
	},
	menu_link_sub:{
		color: "#fff !important",
		margin: "0 15px",
		display: "inline-block",
		fontSize: "16px",
		fontFamily: "Cinzel",
		position: "relative",
		padding: "16px 0",
		cursor: "pointer",
		zIndex: "1"
	},
	menu_link_sub_down:{
		width: "15px",
		marginLeft: "7px"
	},
	menu_link_sub_ul:{
		display: "none"
	},
	menu_link_sub_ul_show:{
		position: "absolute",
		padding: "0",
		textAlign: "left",
		display: "block",
		marginLeft: "-15px",
		background: "#252424"
	},
	mobile_menu_link_sub_ul_show:{
		paddingLeft: "10px",
		textAlign: "left",
		display: "block",
		margin: "0"
	},
	mobile_menu_link_a:{
		textDecoration: "none",
		marginBottom: "5px",
    	display: "block"
	},
	show_menu_in_desktop:{
		display:"inline-block",
		"@media (max-width: 880px)": {
			display: "none",
		}
	},
	menu_link_sub_li:{
		listStyle: "none",
		marginBottom: "9px",
		
	},
	header_container:{
		display: "flex",
		alignItems: "center"
	},
	participate:{
		textAlign: "center",
		maxWidth: "1416px",
		margin: "10px auto 0",
		padding: "0 15px",
		display: "inline-block",
		"@media (max-width: 738px)": {
		}
	},
	participate_a:{
		color: "#fff",
		background: "#141414",
		margin: "0px auto ",
		display: "inline-block",
		fontSize: "13px",
		fontWeight: "600",
		fontFamily: "Poppins",
		border: "2px solid #eee",
		padding: "12.5px 7px",
		borderRadius: "25px",
		textDecoration: "none",
		width: "140px",
		margin: "0 0.5px 7px",
		"@media (min-width: 1000px)": {
			width: "initial",
		},
		"@media (min-width: 1300px)": {
			width: "140px",
		},
		"@media (max-width: 768px)": {
			width: "initial",
			padding: "12.5px 17px",
			transform: "translateY(6px)"

		},
		
	},
}

export default HeaderStyles
