import React, { useEffect, useState, useRef } from 'react'
import { makeStyles } from "@material-ui/core/styles"

import HeaderStyles from "../assets/js/components/HeaderStyles"

import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";
import ConnectWalletButton from "./ConnectWalletButton";
import mailto from "../assets/images/mail-at-white.svg";
import twitter from "../assets/images/twitter-white.svg";
import instagram from "../assets/images/instagram-white.svg";
import telegram from "../assets/images/telegram-white.svg";
import medium from "../assets/images/medium-white.svg";
import linkedin from "../assets/images/linkedin-white.svg";
import youtube from "../assets/images/youtube-white.svg";
import facebook from "../assets/images/facebook-white.png";
import down from "../assets/images/down.svg";
import downBlack from "../assets/images/down-black.svg";
import menu from "../assets/images/menu.png";
import unicrypt from "../assets/images/unicrypt.png";
import mexc from "../assets/images/mexc.png";

const useStyles = makeStyles(HeaderStyles)

function Header(props) {
  const classes = useStyles();
  const [hover, setHover] = React.useState(false);
  const [hover2, setHover2] = React.useState(false);
  const [hover3, setHover3] = React.useState(false);
  const [mobileMenu, setMobileMenu] = React.useState(false);
  const toggleHover = () => {
    setHover(!hover);
  };
  const toggleHover2 = () => {
    setHover2(!hover2);
  };
  const toggleHover3 = () => {
    setHover3(!hover3);
  };
  const toggleMenu = () => {
    setMobileMenu(!mobileMenu);
  };

  return (
      <>
        <div className={(mobileMenu ? classes.overlay_show : classes.overlay)}  onClick={toggleMenu} ></div>
        <div className={classes.sub_header}>
          <span className={classes.mexc_mobile}>IEO on<img className={classes.mexc_mobile_logo} src={mexc} alt="img" /></span>
          <div className={classes.header_unicrypt_mobile}>
            <span className={classes.header_unicrypt_text}>An Incubated Launch on Unicrypt</span>
            <img className={classes.header_unicrypt_logo} src={unicrypt} alt="img" /><br/>
          </div>
        </div>
        <div className={classes.header}>
          <div className={classes.menu}>
            <img className={classes.menu_icon} src={menu} alt="img"  onClick={toggleMenu} />
            <div className={(mobileMenu ? classes.mobile_menu_show : classes.mobile_menu)}>
              <Link to="/" className={classes.mobile_menu_link_a}><span className={classes.mobile_menu_link}>Home</span></Link>
              <span className={classes.mobile_menu_link_sub} onClick={toggleHover}>About us 
                <img className={classes.menu_link_sub_down} src={downBlack} alt="img" />
                <ul className={(hover ? classes.mobile_menu_link_sub_ul_show : classes.menu_link_sub_ul)} >
                  <li className={classes.mobile_menu_link_sub_li}>
                    <Link to="/team" className={classes.mobile_menu_link_a}><span className={classes.mobile_menu_link}>Our Team</span></Link>
                  </li>
                  <li className={classes.mobile_menu_link_sub_li}>
                    <Link to="/mission" className={classes.mobile_menu_link_a}><span className={classes.mobile_menu_link}>vEmpire's mission</span></Link>
                  </li>
                  <li className={classes.mobile_menu_link_sub_li}>
                    <Link to="/integrations-and-partnerships" className={classes.mobile_menu_link_a}><span className={classes.mobile_menu_link}>Integrations & Partnerships</span></Link>
                  </li>
                </ul>
              </span>
              <span className={classes.mobile_menu_link_sub} onClick={toggleHover2}>Unicrypt
                <img className={classes.menu_link_sub_down} src={downBlack} alt="img" />
                <ul className={(hover2 ? classes.mobile_menu_link_sub_ul_show : classes.menu_link_sub_ul)} >
                  <li className={classes.mobile_menu_link_sub_li}>
                    <Link to="/unicrypt" className={classes.mobile_menu_link_a}><span className={classes.mobile_menu_link}>Unicrypts Incubator Launchpad</span></Link>
                  </li>
                  <li className={classes.mobile_menu_link_sub_li}>
                    <Link to="/how-to-participate-in-the-vempire-public-sale" className={classes.mobile_menu_link_a}><span className={classes.mobile_menu_link}>How to participate in our ILO</span></Link>
                  </li>
                </ul>
              </span>
              <Link to="/whitepaper" className={classes.mobile_menu_link_a}><span className={classes.mobile_menu_link}>WHITEPAPER</span></Link>
              <Link to="/nft-handbook" className={classes.mobile_menu_link_a}><span className={classes.mobile_menu_link}>NFT handbook</span></Link>
              <a href="/vEmpire One Pager.pdf" download className={classes.mobile_menu_link_a}><span className={classes.mobile_menu_link}>One Pager</span></a>
              <Link to="/demo" className={classes.mobile_menu_link_a}><span className={classes.mobile_menu_link}>Demo</span></Link>
              <Link to="/contact" className={classes.mobile_menu_link_a}><span className={classes.mobile_menu_link}>Contact</span></Link>

            </div>
          </div>
          <div className={classes.left_section}>
            <span className={classes.mexc}>IEO on<img className={classes.mexc_logo} src={mexc} alt="img" /></span>
          </div>
          <div className={classes.header_container}>
            <div className={classes.participate}>
              <a className={classes.participate_a} target="_blank" href="https://opensea.io/collection/vempire-the-founding-soldiers">
                Our NFTs
              </a>
            </div>
            <div className={classes.show_menu_in_desktop}>
              <Link to="/"><span className={classes.menu_link}>Home</span></Link>
              <span className={classes.menu_link_sub} onMouseEnter={toggleHover} onMouseLeave={toggleHover}>About us 
                <img className={classes.menu_link_sub_down} src={down} alt="img" />
                <ul className={(hover ? classes.menu_link_sub_ul_show : classes.menu_link_sub_ul)} >
                  <li className={classes.menu_link_sub_li}>
                    <Link to="/team"><span className={classes.menu_link}>Our Team</span></Link>
                  </li>
                  <li className={classes.menu_link_sub_li}>
                    <Link to="/mission"><span className={classes.menu_link}>vEmpire's mission</span></Link>
                  </li>
                  <li className={classes.menu_link_sub_li}>
                    <Link to="/integrations-and-partnerships"><span className={classes.menu_link}>Integrations & Partnerships</span></Link>
                  </li>
                </ul>
              </span>
              <span className={classes.menu_link_sub} onMouseEnter={toggleHover2} onMouseLeave={toggleHover2}>Unicrypt
                <img className={classes.menu_link_sub_down} src={down} alt="img" />
                <ul className={(hover2 ? classes.menu_link_sub_ul_show : classes.menu_link_sub_ul)} >
                  <li className={classes.menu_link_sub_li}>
                    <Link to="/unicrypt"><span className={classes.menu_link}>Unicrypts Incubator Launchpad</span></Link>
                  </li>
                  <li className={classes.menu_link_sub_li}>
                    <Link to="/how-to-participate-in-the-vempire-public-sale"><span className={classes.menu_link}>How to participate in our ILO</span></Link>
                  </li>
                </ul>
              </span>
              <span className={classes.menu_link_sub} onMouseEnter={toggleHover3} onMouseLeave={toggleHover3}>Our papers
                <img className={classes.menu_link_sub_down} src={down} alt="img" />
                <ul className={(hover3 ? classes.menu_link_sub_ul_show : classes.menu_link_sub_ul)} >
                  <li className={classes.menu_link_sub_li}>
                    <Link to="/whitepaper"><span className={classes.menu_link}>Download WHITEPAPER</span></Link>
                  </li>
                  <li className={classes.menu_link_sub_li}>
                    <a href="/vEmpire One Pager.pdf" download><span className={classes.menu_link}>One Pager</span></a>
                  </li>
                  <li className={classes.menu_link_sub_li}>
                    <Link to="/nft-handbook"><span className={classes.menu_link}>NFT handbook</span></Link>
                  </li>
                </ul>
              </span>
              <Link to="/demo"><span className={classes.menu_link}>Demo</span></Link>
              <Link to="/contact"><span className={classes.menu_link}>Contact</span></Link>
            </div>
            <ConnectWalletButton text="CONNECT" />
          </div>
        </div>
      </>
      
  )
}

export default Header;
