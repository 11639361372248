import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import TopLink from "../../components/TopLink";

import whatisnftPageStyles from "../../assets/js/pages/whatisnftPageStyles"
import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";

const useStyles = makeStyles(whatisnftPageStyles);

export const HowToParticipateInTheVempirePublicSale = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
        {/* <TopLink/> */}
      <div className={classes.black_content}>
        <Header/>
        <div className={classes.text_content}>
          <h2 className={classes.text_h2}>
            <span className={classes.text_span}>How to Participate in the <span className={classes.font_size_small}>v</span>Empire Public Sale</span>
          </h2>
        </div>
      </div>
      <div className={classes.whitepaper_container}>
        <br/><br/><br/>
        <p className={classes.paragragh}>
          Finally, the fight against centralisation can commence. After weeks of waiting and multiple months of preparation, we’re excited and proud to announce the launch of the vEmpire Gamer Token (VEMP)!
          <br/><br/>This guide is to ensure that everyone knows how & why they should participate in the public presale.
        </p>
        <p className={classes.paragragh_bold}>Important Information (TLDR):
        </p>
        <p className={classes.paragragh_intend}>
          - The presale will take place on Unicrypt on the 31st August 2021 at approx 20:00 PM (BST)
          <br/><br/>- A limited supply of 90 million VEMP tokens will be available for purchase
          <br/><br/>- There will be a fixed price of 125,000 VEMP / per 1 ETH
          <br/><br/>- A purchase limit of 2.5 ETH per participant will be in place
          <br/><br/>- We’ve chosen an ILO so that part of the funds that we raise can be used to
          immediately create a liquidity pool to allow trading to begin smoothly
          <br/><br/>- You can access the presale <a className={classes.link} target="_blank" href="https://app.unicrypt.network/amm/uni-v2/ilo/0xF4f759a7Ee12a1DBd9d6d56bd14d5e22ec9348e6">here</a>
        </p>
        <p className={classes.paragragh_bold}>Why Participate?
        </p>
        <p className={classes.paragragh}>
          vEmpire’s goal is to highlight centralisation issues in supposedly decentralised projects. By doing this, we’re attempting to give the power back to the communities and reward those who’re brave enough to stand up to centralisation with us. The vEmpire project will distribute value generated by a pool of assets to holders of the VEMP token.
          <br/><br/>vEmpire DDAO is the first protocol to allow “DeFi-esque” staking of Metaverse Tokens. This means that VEMP holders will be earning rewards for their MANA, SAND or other tokens that are staked onto the platform, all whilst standing up for what they believe in - true decentralisation.
        </p>
        <p className={classes.paragragh_bold}>How to Participate
        </p>
        <p className={classes.paragragh}>
          On the 31st August, the initial public sale of the VEMP token will begin. It will be hosted on the Unicrypt platform on the Ethereum network. It is only possible to participate with ETH and there will be a purchase limit of 2.5 ETH per participant.
          <br/><br/>In order for the sale to successfully complete, a minimum of 360 ETH must be raised. If this limit is not reached by the time the sale ends, then the sale will fail and investors will receive their initial investment back. A maximum of 720 ETH can be raised in total. If this is reached before the scheduled end time, then the sale will end prematurely and no further VEMP tokens can be purchased.
          <br/><br/>The structure of the sale will be broken down into two rounds. The first round will be exclusively for holders of the Unicrypt tokens UNCL or UNCX. This round will last for two hours. In order to participate in the first round, investors must hold at least 50 UNCL and 3 UNCX. If 720 ETH is raised within the first round, then the presale will be complete. If it is not, then a second round will begin. This round will be available to everyone without participation restrictions and will last for a further 24 hours.
          <br/><br/>IMPORTANT: It is not uncommon for Unicrypt ILOs to complete during the first round. Be prepared to miss out if you do not qualify for participation in round one.
          <br/><br/>When the sale completes, the VEMP token will automatically be listed on Uniswap with a VEMP/ETH liquidity pool already in place. From here, VEMP will be publicly tradable on the decentralised exchange.
        </p>
        <p className={classes.paragragh_bold}>Step-by-step instructions
        </p>
        <p className={classes.paragragh_intend_large}>
          - <a className={classes.link} href="https://app.unicrypt.network/amm/uni-v2/ilo/0xF4f759a7Ee12a1DBd9d6d56bd14d5e22ec9348e6" target="_blank">Go to the public sale page</a>
          <br/><br/> - If you want to participate in the first round you need to hold at least 50
          UNCL or 3 UNCX
          <br/><br/> - Connect your wallet. We recommend MetaMask because there are known
          issues with Trust Wallet
          <br/><br/> - Enter the amount of ETH you wish to spend and confirm
          <br/><br/> - Wait for the presale to complete and then withdraw your new VEMP tokens
          from the same page
          <br/><br/> - If the sale fails because the minimum amount of ETH required is not
          raised, then you will be able to withdraw your initial investment
        </p>
        <p className={classes.paragragh}>
          If you need more information, please do not hesitate to ask questions in our dedicated <a className={classes.link} href="https://t.me/vEmpirediscussion" target="_blank">Telegram group</a>.
        </p>
        <p className={classes.paragragh_bold}>For more information on vEmpire:
        </p>
        <p className={classes.paragragh_intend_large}>
          - Visit our <a className={classes.link} href="/">website</a>
           <br/><br/>- Join in the conversation on <a className={classes.link} href="https://t.me/vEmpirediscussion" target="_blank"> Telegram</a>
           <br/><br/>- Follow us on <a className={classes.link} href="https://twitter.com/vEmpiredigital" target="_blank">Twitter</a> 
           <br/><br/>- Subscribe to our <a className={classes.link} href="https://www.youtube.com/channel/UCjhhTUTgN2xW7IAAXSxvHrw" target="_blank">YouTube Channel</a>
           <br/><br/>- Check out our <a className={classes.link} href="https://medium.com/@v-empire.digital" target="_blank">Medium</a>
           <br/><br/>- Read our <Link className={classes.link} to="/whitepaper" target="_blank">Whitepaper</Link> (we highly recommend it!)
        </p>
        <p className={classes.paragragh_bold}>The vEmpire Team </p>
        <div className={classes.line}></div>
      </div>
      <br/><br/><br/>
      <Footer/>
     
    </div>
  );
};
