import dominic from "../../images/dominic.jpeg";
import mike from "../../images/mike.png";
import warren2 from "../../images/warren2.jpg";
import vEmpire_logo_large from "../../images/vEmpire_logo_large.jpeg";
const teamPageStyles = {
	root: {
		// minWidth: "768px",
		color: "rgb(255, 255, 255)",
		position: "relative",
	},
	black_content: {
		backgroundColor: "#000 !important",
		paddingTop: "30px",
		"@media (max-width: 738px)": {
			paddingTop: "10px",
		}
	},
	image_content: {
		backgroundImage: `url(${vEmpire_logo_large}) !important`,
		borderStyle: "solid !important",
		borderWidth: "0 !important",
		minHeight: "736px",
		backgroundRepeat: "no-repeat !important",
		backgroundSize: "contain !important",
		backgroundPosition: "50% 50%!important",
		width: "auto !important",
		maxWidth: "100% !important",
		margin: "0px auto 50px !important",
		minWidth: "0 !important",
		textAlign: "start !important",
		"@media (min-width: 1200px)": {
			// transform: "translateY(-64px)"
		},
		"@media (max-width: 738px)": {
			minHeight: "100vw",
		}
	},
	down:{
		textAlign: "center"
	},
	down_icon:{
		width: "75px",
		cursor: "pointer"
	},
	text_content: {
		background: "#474747",
		textAlign: "center",
		padding: "16.5px 40px",
	},
	text_h2: {
		fontSize: "30px",
		lineHeight: "initial",
		textTransform: "uppercase",
		margin: "8px 0!important",
	},
	text_span: {
		fontFamily: "Cinzel",
		display: "initial",
		fontWeight: 400,
	},
	form_section:{
		"@media (max-width: 738px)": {
			textAlign: "center !important",
			padding: "0 20px",
		}
	},
	menulink:{
		"@media (max-width: 738px)": {
			display: "block",
		}
	},
	textAlignRight: {
		textAlign: "right",
	},
	recaptcha: {
		position: "absolute",
		bottom: "10px",
		left: "0px",
	},
	dialogWrapper: {},
	member: {
		background: "#000",
		minHeight: "100vh",
		display: "flex",
	},
	member_gray: {
		background: "#474747",
		minHeight: "100vh",
		display: "flex",
	},
	member_container:{
		width: "50%;",
		minHeight: "100vh",
		position: "absolute",
		display: "flex",
		"@media (max-width:992px)": {
			width: "100%;",
		}
	},
	member_profile:{
		width: "100%",
		backgroundRepeat: "no-repeat",
		backgroundSize: "cover",
		backgroundPosition: "center top"
	},
	dominic:{
		backgroundImage: `url(${dominic})`,
	},
	mike:{
		backgroundImage: `url(${mike})`,
	},
	warren:{
		backgroundImage: `url(${warren2})`,
	},
	overlay: {
		background: "linear-gradient(90deg, rgba(0,0,0,0.64) 50%, rgba(0,0,0,1) 90%, rgba(0,0,0,1) 100%)",
		width: "100%;",
		minHeight: "100vh",
		position: "absolute",
		display: "flex"
	},
	overlay_gray: {
		background: "linear-gradient(90deg, rgba(71,71,71,0.64) 50%, rgba(71,71,71,1) 90%, rgba(71,71,71,1) 100%)",
		width: "100%;",
		minHeight: "100vh",
		position: "absolute",
		display: "flex"
	},
	member_description_container:{
		marginLeft: "auto",
		display: "flex",
		padding: "0 84px",
		"@media (max-width: 992px)": {
			flexDirection: "column",
			padding: "0 15px",
		}
	},
	member_picture:{
		maxWidth: "286px",
		marginLeft: "auto",
		marginRight: "85px",
		textAlign: "center",
		zIndex: 1,
		paddingTop: "70px",
		"@media (max-width: 992px)": {
			margin: "auto",
		}
	},
	member_image: {
		width: "100%",
		marginBottom: "26px",
		borderRadius: "50%",
		"@media (max-width: 768px)": {
			display: "none",
		}
	},
	member_description: {
		maxWidth: "373px",
		marginLeft: "auto",
		display: "flex",
		flexDirection: "column",
		paddingTop: "405px",
		zIndex: 1,
		"@media (max-width: 992px)": {
			paddingTop: "100px"
		}
	},
	name:{
		fontSize: "25px",
		fontFamily: "Poppins",
		display: "block",
		"@media (max-width: 768px)": {
			display: "inline-block",
			marginRight: "15px"
		}
	},
	role:{
		fontSize: "20px",
		fontFamily: "Poppins",
		fontWeight: "bolder",
		display: "block",
		
	},
	line: {
		background: "#fff",
		width: "62px",
		height: "3px",
		margin: "7px 0 20px"
	},
	member_social_link:{
		margin: "0 10px",
		display: "inline-block"
	},
	member_social:{
		width: "32px"
	},
	full_name:{
		fontSize: "18px",
		fontFamily: "Poppins",
		fontWeight: "bolder",
		display: "block",
	},
	description:{
		fontSize: "18px",
		fontFamily: "Poppins",
		lineHeight: "1.3"
	},
	partner: {
		textAlign: "center",
		color: '#000',
		padding: "100px 0 92px"
	},
	partner_title:{
		fontSize: "23px",
		fontFamily: "Poppins",
		display: "block",
		marginBottom: "47px"
	},
	partner_img:{
		display: "block",
		width: "100%",
		maxWidth: "368px",
		margin: "auto",
		marginBottom: "47px"
	},
	partner_desc:{
		fontSize: "20px",
		fontFamily: "Poppins",
		maxWidth: "1000px",
    	margin: "auto"
	},
	logo_box: {
		textAlign: "center",
		padding: "68px 0",
		background: "#474747"
	},
	logo_item: {
		maxWidth: "404px",
		maxHeight: "121px",
		margin: "0 54px",
		verticalAlign: "middle",
		"@media (max-width: 738px)": {
			maxWidth: "90%",
			margin: "0 auto",
			display: "block"
		}
	}
}

export default teamPageStyles
