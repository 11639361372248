import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import TopLink from "../../components/TopLink";

import teamPageStyles from "../../assets/js/pages/teamPageStyles"

import dominic from "../../assets/images/dominic.jpeg";
import mike2 from "../../assets/images/mike_2.png";
import warren1 from "../../assets/images/warren1.png";
import twitter from "../../assets/images/twitter_alt.svg";
import linkedin from "../../assets/images/linkedin_alt.svg";
import oxblock_logo from "../../assets/images/oxblock_logo.png";
import logo_1 from "../../assets/images/logo_1.png";
import logo_2 from "../../assets/images/logo_2.png";
import logo_3 from "../../assets/images/logo_3.png";
import logo_4 from "../../assets/images/logo_4.png";
import unicryptWhite from "../../assets/images/unicrypt-white.png";
import down from "../../assets/images/down.svg";

const useStyles = makeStyles(teamPageStyles);
  

export const Team = () => {
  const classes = useStyles();
 
  let scrollRef = useRef()
  const executeScroll = () =>  window.scrollTo({ behavior: 'smooth', top: scrollRef.current.offsetTop })
  return (
    <div className={classes.root}>
        {/* <TopLink/> */}
      <div className={classes.black_content}>
        <Header/>
        <div className={classes.image_content}/>
        <div className={classes.down}>
          <img className={classes.down_icon} src={down} alt="img"  onClick={executeScroll}  />
        </div>
        <div className={classes.text_content}>
          <h2 className={classes.text_h2}>
            <span className={classes.text_span}><span className='font_size_small'>v</span>Empire - THE FRONTLINE FIGHT AGAINST CENTRALISAITION</span>
          </h2>
        </div>
      </div>
      
      <div className={`${classes.member}`} ref={scrollRef}>
        <div className={classes.member_container}>
          <div className={`${classes.member_profile} ${classes.dominic}`}></div>
          <div className={`${classes.overlay}`}></div>
        </div>
        <div className={classes.member_description_container}>
          <div className={classes.member_picture}>
            <img className={classes.member_image} src={dominic} alt="img" />
            <span className={classes.name}>Dominic</span>
            <a href="https://www.linkedin.com/in/dominic-ryder-9024699a/" className={classes.member_social_link} target="_blank">
              <img className={classes.member_social} src={linkedin} alt="img" />
            </a>
            <a href="https://twitter.com/dominicVEMP" className={classes.member_social_link} target="_blank">
              <img className={classes.member_social} src={twitter} alt="img" />
            </a>
          </div>
          <div className={classes.member_description}>
            <span className={classes.role}> Chief Executive Officer</span>
            <span className={classes.line}></span>
            <span className={classes.full_name}> Dominic Ryder (Romulus)</span>
            <p className={classes.description}>
              After beginning his career in the world of stock broking, Dominic quickly advanced from a junior broker at nineteen to a Senior Derivatives Trader & Head of Desk. 
              <br/><br/>
              Dominic’s career eventually took him through wealth management, as a partner at St James’s Place, and eventually he found his true passion in venture capital where he first encountered blockchain technology and NFTs.
              <br/><br/>
              An anarchist at heart, the original rebellious nature and reasoning for decentralisation ignited a passion which eventually created vEmpire DDAO.
            </p>
          </div>
        </div>  
      </div>
      
      <div className={`${classes.member_gray} `}>
        <div className={classes.member_container}>
          <div className={`${classes.member_profile} ${classes.mike}`}></div>
          <div className={`${classes.overlay_gray}`}></div>
        </div>
        <div className={classes.member_description_container}>
          <div className={classes.member_picture}>
            <img className={classes.member_image} src={mike2} alt="img" />
            <span className={classes.name}>Mike</span>
            <a href="https://www.linkedin.com/in/mike-ryder-77700163/" className={classes.member_social_link}  target="_blank">
              <img className={classes.member_social} src={linkedin} alt="img" />
            </a>
          
          </div>
          <div className={classes.member_description}>
            <span className={classes.role}>Chief Operating Officer</span>
            <span className={classes.line}></span>
            <span className={classes.full_name}>Mike Ryder (Remus) </span>
            <p className={classes.description}>
              As an avid gamer from a young age, Mike has been cutting his teeth in the creative and technical areas of the audio visual industry designing and programming interactive solutions for high profile clients.
              <br/><br/>
              Using his technical mindedness , he is combining that with his great interest in gaming to create unique playable features & concepts to keep the empire entertained.
            </p>
          </div>
        </div>  
      </div>     
      <div className={classes.partner}>
        <span className={classes.partner_title}>Technology Partner</span>
        <img className={classes.partner_img} src={oxblock_logo} alt="img" />
        <p className={classes.partner_desc}>
          0xblock have worked for countless blockchain projects, as well as the team boasting significant clients including JP Morgan & Goldman Sachs. They have a team of over forty developers with a huge array of knowledge.
        </p>
      </div>
      <div className={`${classes.member}`}>
        <div className={classes.member_container}>
          <div className={`${classes.member_profile} ${classes.warren}`}></div>
          <div className={`${classes.overlay}`}></div>
        </div>
        <div className={classes.member_description_container}>
          <div className={classes.member_picture}>
            <img className={classes.member_image} src={warren1} alt="img" />
            <span className={classes.name}>Warren</span>
            <a href="https://www.linkedin.com/in/warrendrogers/" className={classes.member_social_link} target="_blank">
              <img className={classes.member_social} src={linkedin} alt="img" />
            </a>
          
          </div>
          <div className={classes.member_description}>
            <span className={classes.role}> Adviser</span>
            <span className={classes.line}></span>
            <p className={classes.description}>
              Warren Rogers - Chief Strategy Officer at Blockware Solutions.
              <br/><br/>
              From Iowa, USA, Warren and Dominic first met whilst working for Downing LLP, a venture capital firm in London. They quickly found common ground in their love of blockchain. 
              <br/><br/>
              Prior to Downing & his role at Blockware Solutions, Warren founded the RHI Group, was a founding partner of DeepWell Ventures, whilst also being a Ventures Partner at Airbus. 
              <br/><br/>
              He has managed a directional hedge fund portfolio  focused on natural resources and commodities and has also led the Global Derivatives Trading for Janus Henderson in Denver and Singapore, managing billions of dollars of AUM exposures.
            </p>
          </div>
        </div>  
      </div>     
      <div className={classes.logo_box}>
          <img className={classes.logo_item} src={logo_4} alt="img" />
          <img className={classes.logo_item} src={logo_1} alt="img" />
          <img className={classes.logo_item} src={unicryptWhite} alt="img" />

      </div>

      <Footer/>
     
    </div>
  );
};
