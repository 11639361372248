import { Hidden } from "@material-ui/core"
import vEmpire_logo_large from "../../images/vEmpire_logo_large.jpeg";

const contactPageStyles = {
	root: {
		// minWidth: "768px",
		color: "rgb(255, 255, 255)",
		position: "relative",
	},
	black_content: {
		backgroundColor: "#000 !important",
		paddingTop: "30px",
		"@media (max-width: 738px)": {
			paddingTop: "10px",
		}
	},
	contact_content: {
		maxWidth: "815px !important",
		margin: "0px auto 50px !important",
		display: "flex",
		justifyContent: "space-between",
		padding: "10px 0",
		"@media (max-width: 738px)": {
			flexDirection: "column",
			alignItems: "center"
		}
	},
	contact_content_ul:{
		listStyle: "none",
		margin: "0",
		paddingLeft: "0"
	},
	contact_content_li:{
		marginBottom: "19px"
	},
	contact_content_a:{
		borderBottom: "1px solid #fff",
		fontSize: "18px",
		fontFamily: "Poppins",
		fontWeight: "700",
		textDecoration: "none",
		color: "#fff"
	},

	text_content: {
		background: "#474747",
		textAlign: "center",
		padding: "16.5px 40px",
	},
	text_h2: {
		fontSize: "30px",
		lineHeight: "initial",
		textTransform: "uppercase",
		margin: "8px 0!important",
	},
	text_span: {
		fontFamily: "Cinzel",
		display: "initial",
		fontWeight: 400,
	},
	form_section:{
		"@media (max-width: 738px)": {
			textAlign: "center !important",
			padding: "0 20px",
		}
	},
	menulink:{
		"@media (max-width: 738px)": {
			display: "block",
		}
	},
	contact_title:{
		fontSize: "18px",
		fontFamily: "Poppins",
		fontWeight: "700",
		marginBottom: "10px",
		display: "block"
	},
	form_group:{
		marginBottom: "30px"
	},
	form_group_label:{
		display: "block",
		fontSize: "18px",
		fontFamily: "Poppins",
		fontWeight: "500",
		marginBottom: "6px"
	},
	form_group_input:{
		border: "2px solid #fff",
		background: "#fff",
		fontSize: "18px",
		fontFamily: "Poppins",
		fontWeight: "500",
		width: "439px",
		maxWidth: "439px",
		padding: "10px 10px",
		"@media (max-width: 738px)": {
			width: "100%",
		}
	},
	form_group_textarea:{
		border: "2px solid #fff",
		background: "#fff",
		fontSize: "18px",
		fontFamily: "Poppins",
		fontWeight: "500",
		width: "439px",
		maxWidth: "439px",
		padding: "10px 10px",
		height: "170px",
		"@media (max-width: 738px)": {
			width: "100%",
		}
	},
	form_submit: {
		border: "0",
		background: "#fff",
		color: "#000",
		fontSize: "18px",
		fontFamily: "Poppins",
		fontWeight: "600",
		borderRadius: "15px",
		padding: "4px 27px",
		float: "right"
	},
	form_submited: {
		border: "0",
		background: "#4bae8e",
		color: "#000",
		fontSize: "18px",
		fontFamily: "Poppins",
		fontWeight: "600",
		borderRadius: "15px",
		padding: "4px 27px",
		float: "right"
	},
	textAlignRight: {
		textAlign: "right",
	},
	recaptcha: {
		position: "absolute",
		bottom: "10px",
		left: "0px",
	},
	dialogWrapper: {},
}

export default contactPageStyles
