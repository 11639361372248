import logos from "../../images/logos.png";
import bg_1 from "../../images/bg_1.png";
import bg_2 from "../../images/bg_2.png";
const unicryptPageStyles = {
	root: {
		// minWidth: "768px",
		color: "rgb(255, 255, 255)",
		position: "relative",
	},
	black_content: {
		backgroundColor: "#000 !important",
		paddingTop: "30px",
		"@media (max-width: 738px)": {
			paddingTop: "10px",
		}
	},
	image_content: {
		backgroundImage: `url(${logos}) !important`,
		borderStyle: "solid !important",
		borderWidth: "0 !important",
		minHeight: "736px",
		backgroundRepeat: "no-repeat !important",
		backgroundSize: "contain !important",
		backgroundPosition: "50% 50%!important",
		width: "auto !important",
		maxWidth: "90% !important",
		margin: "0px auto 50px !important",
		minWidth: "0 !important",
		textAlign: "start !important",
		"@media (max-width: 738px)": {
			minHeight: "100vw",
		}
	},
	down:{
		textAlign: "center"
	},
	down_icon:{
		width: "75px",
		cursor: "pointer"
	},
	text_content: {
		background: "#474747",
		textAlign: "center",
		padding: "16.5px 40px",
	},
	text_h2: {
		fontSize: "30px",
		lineHeight: "initial",
		textTransform: "uppercase",
		margin: "8px 0!important",
	},
	text_span: {
		fontFamily: "Cinzel",
		display: "initial",
		fontWeight: 400,
	},
	form_section:{
		"@media (max-width: 738px)": {
			textAlign: "center !important",
			padding: "0 20px",
		}
	},
	menulink:{
		"@media (max-width: 738px)": {
			display: "block",
		}
	},
	textAlignRight: {
		textAlign: "right",
	},
	recaptcha: {
		position: "absolute",
		bottom: "10px",
		left: "0px",
	},
	dialogWrapper: {},
	description:{
		padding: "165px 0 190px 0",
		backgroundImage: `url(${bg_1})`,
		backgroundRepeat: "no-repeat",
		backgroundSize: "contain",
		backgroundPosition: "left center",
	},
	description2:{
		padding: "165px 0 190px 0",
		backgroundImage: `url(${bg_2})`,
		backgroundRepeat: "no-repeat",
		backgroundSize: "contain",
		backgroundPosition: "left center",
	},
	white_line:{
		width: "63px",
		height: "3px",
		background: "#fff",
		display: "inline-block",
		marginBottom: "10px"
	},
	description_parent:{
		margin: "auto",
		padding: "0 15px",
		maxWidth: "1165px",
		display: "flex",
    	justifyContent: "flex-end",
		"@media (max-width: 738px)": {
			justifyContent: "center"
		}
	},
	description_p:{
		fontSize: "18px",
		fontFamily: "Poppins",
		maxWidth: "613px"
	}
}

export default unicryptPageStyles
