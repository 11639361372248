const FooterStyles = {
	footer:{
		"@media (min-width: 992px)": {
			height: "350px"
		}
	},
    grey_content: {
		color: "#000",
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: "20px 110px 20px",
		"@media (max-width: 738px)": {
			padding: "20px 20px 20px",
			flexDirection: "column"
		}
	},
	social_content: {
		maxWidth: "none",
		marginLeft: 0,
		marginRight: 0,
		paddingLeft: "40px",
		paddingRight: "40px",
		display: "flex",
		padding: "0px 40px",
		justifyContent: "center",
		flexWrap: "wrap",
		"@media (min-width: 992px)": {
			transform: "translateY(-72px)"
		}
	},
	social_a: {
		width: "44px",
		height: "44px",
		margin: "0 2px!important",
		fontSize: "16px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		"&:hover": {
			tranform: "scale(1.05)"
		},
	},
	copyright: {
		color: "#000",
		fontSize: "18px",
		padding: "14px 0 20px",
		textAlign: "center",
		fontFamily: "Poppins",
		"@media (min-width: 992px)": {
			transform: "translateY(-72px)"
		}
	},
    social_span: {
		padding: "4px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		width: "50px"
	},
	social_image: {
		width: "30px",
	},
	mailto_image: {
		height: "auto",
	},
	form_content: {
		"@media (max-width: 738px)": {
			paddingLeft: "0",
			paddingRight: "0"
		}
	},
	form_title: {
		paddingLeft: ".9375em",
		paddingRight: ".9375em",
	},
	form_h3: {
		fontFamily: "Poppins!important",
		fontWeight: "600",
		fontStyle: "normal",
		fontSize: "25px",
		margin: "10px 0 0",
	},
	form_section: {
		paddingLeft: ".9375em",
		paddingRight: ".9375em",
		width: "414px",
		"@media (max-width: 738px)": {
			width: "100%",
			paddingLeft: "20px",
			paddingRight: "20px"
		}
	},
	links_item: {
		color: "#000",
		display: "inline-block",
		textDecoration: "none",
		fontSize: "25px",
		marginBottom: "13px",
		fontFamily: "Poppins",
		fontWeight: 600,
		borderBottom: "1px solid #000"
	},
	form_form_content: {
		width: "100%",
		display: "inline-block",
		"@media (max-width: 738px)": {
			width: "100%",
			display: "flex",
			flexDirection: "column"
		}
	},
	form_label: {
		fontFamily: "Poppins",
		fontWeight: 300,
	},
	form_email: {
		fontFamily: "Poppins",
		fontSize: "18px",
		border: "2px solid #000",
		color: "#000",
		width: "100%",
		maxWidth: "414px",
		margin: "3px 0",
		height: "44px",
		padding: "0 10px",
		"@media (max-width: 738px)": {
			width: "initial"
		}
	},
	form_address: {
		marginTop: "10px",
	},
	form_textarea: {
		fontSize: "16px",
		backgroundColor: "black",
		border: "2px solid #fff",
		width: "100%",
		color: "white",
		margin: "3px 0",
		height: "80px",
		padding: "10px",
		minHeight: "50px",
	},
	form_submit_content: {
		margin: "10px 0 20px",
		maxWidth: "210px",
		width: "100%",
		border: "1px solid #646464",
		display: "flex",
		"@media (max-width: 738px)": {
			maxWidth: "100%"
		}
	},
	form_submit: {
		fontSize: "15px",
		padding: "10px 0",
		width: "100%",
		backgroundColor: "#000",
		borderColor: "#646464",
		color: "#ffffff",
		outline: 0,
		border: 0,
		cursor: "pointer",
		textAlign: "center",
		fontFamily: "inherit",
		fontFamily: "Poppins",
		textDecoration: "none",
		"&:hover": {
			backgroundColor: "#fff",
			borderColor: "#414345",
			color: "#414345",
		},
	},
	form_submit_subscribed: {
		backgroundColor: "#239866 !important",
		"&:hover": {
			backgroundColor: "#239866",
			borderColor: "#414345",
			color: "#fff",
		},
	},
}

export default FooterStyles
