import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Footer from "../../components/Footer";
import Header from "../../components/Header";
import TopLink from "../../components/TopLink";

import {
  BrowserRouter as Router,
  Link
} from "react-router-dom";
import whitepaperPageStyles from "../../assets/js/pages/whitepaperPageStyles"
import graphic_1 from "../../assets/images/graphic_1.svg";
import graphic_2 from "../../assets/images/graphic_2.svg";
import graphic_3_1 from "../../assets/images/graphic_3.1.svg";
import graphic_3_2 from "../../assets/images/graphic_3.2.svg";
import graphic_4 from "../../assets/images/graphic_4.svg";
import graphic_5 from "../../assets/images/graphic_5.svg";
import down from "../../assets/images/down.svg";


const useStyles = makeStyles(whitepaperPageStyles);

export const Handbook = () => {
  const classes = useStyles();

  let scrollRef = useRef()
  const executeScroll = () =>  window.scrollTo({ behavior: 'smooth', top: scrollRef.current.offsetTop })
  return (
    <div className={classes.root}>
        {/* <TopLink/> */}
      <div className={classes.black_content}>
        <Header/>
        
        <div className={classes.image_content} />
        <div className={classes.download_parent} >
          <a href="/vEmpire-TheBeginning-Handbook.pdf" download className={classes.download_link} >Download NFT handbook as PDF</a>
        </div>
        <div className={classes.text_content}>
          <h2 className={classes.text_h2}>
            <span className={classes.text_span}>The Front line in the fight against centralisation</span>
          </h2>
        </div>
      </div>
      
      <Footer/>
     
    </div>
  );
};
