import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";
import './App.css';
import { Home } from "./pages/home";
import { Team } from "./pages/team";
import { Whitepaper } from "./pages/whitepaper";
import { Unicrypt } from "./pages/unicrypt";
import { Mission } from "./pages/mission";
import { Contact } from "./pages/contact";
import { WhatIsNft } from "./pages/whatisnft";
import { Handbook } from "./pages/handbook";
import { WhatIsVirtualRealestate } from "./pages/whatisvirtualrealestate";
import { HowToParticipateInTheVempirePublicSale } from "./pages/howtoparticipate";
import { Faq } from "./pages/faq";
import { IntegrationsAndPartnerships } from "./pages/integrations-and-partnerships";
import { Demo } from "./pages/demo";

export const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path='/team' component={Team} />
        <Route path='/whitepaper' component={Whitepaper} />
        <Route path='/unicrypt' component={Unicrypt} />
        <Route path='/mission' component={Mission} />
        <Route path='/contact' component={Contact} />
        <Route path='/what-is-nft' component={WhatIsNft} />
        <Route path='/nft-handbook' component={Handbook} />
        <Route path='/what-is-virtual-real-estate-&-what-makes-it-valuable' component={WhatIsVirtualRealestate} />
        <Route path='/how-to-participate-in-the-vempire-public-sale' component={HowToParticipateInTheVempirePublicSale} />
        <Route path='/integrations-and-partnerships' component={IntegrationsAndPartnerships} />
        <Route path='/faq' component={Faq} />
        <Route path='/demo' component={Demo} />
      </Switch>
    </Router>
  );
};

export default App;
