import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import TopLink from "../../components/TopLink";

import whatisnftPageStyles from "../../assets/js/pages/whatisnftPageStyles"


const useStyles = makeStyles(whatisnftPageStyles);

export const WhatIsVirtualRealestate = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
        {/* <TopLink/> */}
      <div className={classes.black_content}>
        <Header/>
        <div className={classes.text_content}>
          <h2 className={classes.text_h2}>
            <span className={classes.text_span}>What Is Virtual Real Estate & What Makes It Valuable?</span>
          </h2>
        </div>
      </div>
      <div className={classes.whitepaper_container}>
        <br/><br/><br/>
        <p className={classes.paragragh}>
          The COVID-19 pandemic brought with it sweeping changes to interaction. As the world came to a standstill, people were forced into isolation. This had serious repercussions on the way that people interacted with each other. When society had never felt more alone, digital media offered a bridge to a new world and it presented an exciting range of new possibilities. And because of this, we witnessed a great migration to the virtual world.
          <br/><br/>Now virtual worlds and virtual forms of interaction have taken centre stage. Yes, the pandemic forced society to explore alternatives to human interaction, but it only made the inevitable push towards a digital world more urgent. Before, the digital world was almost always treated as an afterthought for expanding interaction beyond the reaches of physical space. However, now people who never previously showed any interest in virtual worlds are keen to explore and understand its capabilities.
          <br/><br/>Virtual socialization is inevitable. Human interaction has become overwhelmingly digital. Most people have moved their social and business relationships to digital forms of interaction. And now it's looking likely that society’s new habits will become permanent. However virtual worlds are not new. VR projects such as SimCity date back to as far as 1989. And with the introductoin of new platforms such as Decentraland and The Sandbox for example, we’re witnessing a major shift towards living in a virtual world. And because of this, there are amazing potential opportunities for investment.
        </p>
        <p className={classes.paragragh_bold}>What is virtual real estate?
        </p>
        <p className={classes.paragragh}>
          The virtual world is known as the ‘metaverse’. Here users are free to build, explore and interact with others however they want, just like in the real world. However like the real world, in order to build infrastructure, land is required to build on top of. This land is what is referred to as ‘virtual real estate’. And just like Bitcoin and other cryptocurrencies, virtual real estate is extremely scarce. This naturally means that people are going to speculate on it. However, as we transition to a more digital world, this speculation will become sound investment strategies due to the increased demand for space in virtual reality. And because of this, digital real estate is becoming a legitimate asset class.
          <br/><br/>Now, millions of dollars are being invested into virtual real estate. Investment is simple. It occurs through the purchase and exchange of NFTs. And although these NFTs are not as liquid as cryptocurrencies, they are exchangeable through transactions that are much more streamlined and transparent than real world transactions. There is even a secondary market where these assets can be exchanged between users at near real-world prices. And although blockchain powers these NFT transactions, it also acts as a base layer for the monetization of virtual real estate too.
        </p>
        <p className={classes.paragragh_bold}>What can you do with virtual real estate?
        </p>
        <p className={classes.paragragh}>
          Virtual real estate can be described as a place where people can enjoy a second life. Since the virtual world is not limited unlike the real world, the possibilities are endless. Owning virtual land gives individuals the freedom to build their own structures, attractions, games and even applications. There are also more practical use cases such as building virtual offices to enable more effective collaboration. Use cases for virtual real estate extends to all industries. And because of this, investment is becoming increasingly popular.
          <br/><br/>As an investment, virtual real estate offers opportunities greater than real world possibilities. This is because there isn’t a limit on what can be done in virtual worlds. One of the most prominent use cases at the moment is the display of NFTs and digital artwork. However art is just one part of this new economy. And because of the various possibilities of VR, virtual real estate can now be monetized in ways similar to physical real estate.
          <br/>For those with great imaginations, the possibilities are endless. You can do much more than simply speculate on the future price. For example, virtual worlds with high traffic are prime locations for commercial advertising. This opens the door for virtual landowners to lease advertising space similar to how sporting venues do. Alternatively, businesses can lease virtual real estate to create virtual showrooms and pop up stores. And beyond basic advertising, developers can even create their own virtual arenas and host events such as music concerts and charge admission.
        </p>
        <p className={classes.paragragh_bold}>But what makes virtual real estate valuable?
        </p>
        <p className={classes.paragragh}>
          Unlike a lot of NFT use cases, digital land ownership isn’t just about social status. There are actually multiple reasons why it can be seen as a sound investment. As with all crypto assets, value is determined by scarcity and desirability. And over time, digital real estate has proven to not only hold its value, but to actually appreciate in value. In fact, over the previous two years, the average price of digital real estate has increased tenfold. However what truly makes digital real estate valuable is exactly what makes physical real estate valuable. Just like how you can monetize physical real estate, you can also monetize digital real estate.
          <br/><br/>Purely from a capital appreciation standpoint, when you have a finite amount of space and an increasing population, or in this case user base, there will be an increase in land value. On top of that, the possibilities to monetize digital land just like you would in the real world presents fantastic passive income opportunities. And generating easy passive income while holding onto an appreciating asset is a recipe for investment success.
        </p>
        <p className={classes.paragragh_bold}>Conclusion</p>
        <p className={classes.paragragh}>
          Part of the allure of owning virtual real estate is based on the optimism of virtual reality’s potential. A combination of genuine scarcity and increased desire for virtual land will cause an increase in price. For digital land to truly thrive, it's necessary that virtual reality becomes mass adopted. This is something that is looking increasingly likely. However in the meantime, the quest for resilient investable assets will continue in this period of low interest and high inflation. Blockchain based assets are proving to perform better than traditional assets. Evidence of acceptance and adoption of digital real estate can be demonstrated by the sheer depth of the market and the size of recent transactions. It’s true that digital forms of traditional assets are becoming increasingly popular, Bitcoin is a great example of this. It's very possible that virtual real estate will be the next asset to outperform its traditional counterpart.
        </p>
      </div>
      <br/><br/><br/>
      <Footer/>
     
    </div>
  );
};
