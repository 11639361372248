import React from 'react'
import { makeStyles } from "@material-ui/core/styles"

import DialogButtonStyles from "../assets/js/components/DialogButtonStyles"

const useStyles = makeStyles(DialogButtonStyles)

function DialogButton(props) {

    const { text, icon, iconClasses, handleClick } = props

    const classes = useStyles();

    return (
        <>
            <div className={ classes.root } onClick={ handleClick }>
                {
                    icon === null ? 
                    (<div className={ classes.emptyIcon }></div>)
                    :(<img src={ icon } className={ classes.btnIcon + " " + iconClasses } />)
                }
                <p className={ classes.text }>{ text }</p>
            </div>            
        </>
    )
}

export default DialogButton
