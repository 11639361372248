const DialogButtonStyles = {
    root: {
        border: "1px solid #646464",
        maxWidth: "250px",
        minHeight: "250px",
        width: "100%",
        borderRadius: "4px",
        marginRight: "10px",
        marginLeft: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        cursor: "pointer"
    },
    text: {
        textAlign: "center",
        fontSize: "20px",
        color: "white",
    },
    btnIcon: {
        width: "80px",
        height: "80px"
    },
    emptyIcon: {
        width: "100%",
        height: "100px"
    }
}

export default DialogButtonStyles
