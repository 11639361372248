import vEmpire_logo_large from "../../images/vEmpire_logo_large.jpeg";

const integrationsAndPartnershipsPageStyles = {
	root: {
		// minWidth: "768px",
		color: "rgb(255, 255, 255)",
		position: "relative",
	},
	black_content: {
		backgroundColor: "#000 !important",
		paddingTop: "30px",
		"@media (max-width: 738px)": {
			paddingTop: "10px",
		}
	},
	image_content: {
		backgroundImage:
			`url(${vEmpire_logo_large}) !important`,
		borderStyle: "solid !important",
		borderWidth: "0 !important",
		minHeight: "736px",
		backgroundRepeat: "no-repeat !important",
		backgroundSize: "contain !important",
		backgroundPosition: "50% 50%!important",
		width: "auto !important",
		maxWidth: "100% !important",
		margin: "0px auto 50px !important",
		minWidth: "0 !important",
		textAlign: "start !important",
		"@media (min-width: 1200px)": {
			// transform: "translateY(-64px)"
		},
		"@media (max-width: 738px)": {
			minHeight: "100vw",
		}
	},
	text_content: {
		background: "#474747",
		textAlign: "center",
		padding: "16.5px 40px",
	},
	text_h2: {
		fontSize: "35px",
		lineHeight: "initial",
		textTransform: "uppercase",
		margin: "8px 0!important",
	},
	text_span: {
		fontFamily: "Cinzel",
		display: "initial",
		fontWeight: 400,
	},
	
	form_section:{
		"@media (max-width: 738px)": {
			textAlign: "center !important",
			padding: "0 20px",
		}
	},
	textAlignRight: {
		textAlign: "right",
	},
	recaptcha: {
		position: "absolute",
		bottom: "10px",
		left: "0px",
	},
	dialogWrapper: {},
	whitepaper_container: {
		margin: "auto",
		maxWidth: "858px",
		color: "#000",
		padding: "0 15px"
	},
	main_title:{
		fontSize: "30px",
		fontWeight: "700",
		fontFamily: "Cinzel",
		textAlign: "center"
	},
	main_title: {
		fontSize: "30px",
		fontWeight: "700",
		fontFamily: "Cinzel",
		textAlign: "center"
	},
	sub_title: {
		fontSize: "30px",
		fontWeight: "500",
		fontFamily: "Cinzel",
		textAlign: "center"
	},
	description: {
		fontSize: "20px",
		fontFamily: "Poppins",
		fontWeight: "400",
		textAlign: "center",
		marginBottom: "8px"
	},
	email_parent:{
		textAlign: "center",
		marginBottom: "40px"
	},
	email: {
		fontSize: "18px",
		fontFamily: "Poppins",
		textAlign: "center",
		color: "#474747"
	},
	paragragh: {
		fontSize: "18px",
		fontWeight: "500",
		fontFamily: "Poppins",
		marginBottom: "1.8rem"
	},
	paragragh_intend: {
		fontSize: "18px",
		fontWeight: "400",
		fontFamily: "Poppins",
		marginBottom: "1.8rem",
		marginLeft: "62px",
		"@media (max-width: 738px)": {
			marginLeft: "12px",
		}
	},
	paragragh_bold: {
		fontSize: "18px",
		fontFamily: "Poppins",
		fontWeight: 700
	},
	paragragh_big: {
		fontSize: "25px",
		fontFamily: "Poppins",
		fontWeight: 500
	},
	paragragh_medium: {
		fontSize: "18px",
		fontFamily: "Poppins",
		fontWeight: 600
	},
	paragragh_italic: {
		fontSize: "18px",
		fontStyle: "italic",
		fontFamily: "Poppins",
		fontWeight: 500
	},
	link:{
		fontFamily: "Poppins",
		fontSize: "18px",
		color: "#000",
		wordBreak: "break-all",
		fontWeight: 500

	},
	by:{
		fontSize: "18px",
		fontFamily: "Poppins",
		fontWeight: 600,
		marginBottom: "22px",
		marginTop: "111px"
	},
	introduction:{
		fontSize: "18px",
		fontFamily: "Poppins",
		fontWeight: 700,
	},
	line:{
		width: "62px",
		height: "3px",
		background: "#000",
		display: "inline-block",
		marginBottom: "10px"
	},
	logos:{
		display: "flex",
		marginTop: "100px"
	},
	logos_ul:{
		width: "100%",
		maxWidth: "1200px",
		margin: "auto",
		padding: "0",
	},
	logos_li:{
		width: "33%",
		float: "left",
		border: "1px dashed #ddd",
		textAlign: "center",
    	padding: "50px 0",
		position: "relative",
		margin: "auto",
		"@media (max-width: 738px)": {
			padding: "20px 0",
			width: "200px",
			float: "none"
		}
	},
	logos_a:{
		display: "block",
		height: "170px",
		position: "relative",
	},
	logos_img:{
		maxWidth: "80%",
		maxHeight: "90%",
		position: "absolute",
		transform: "translate(-50%, -50%)",
		top: "50%",
		left: "50%",
	}
}
export default integrationsAndPartnershipsPageStyles
