const ConnectWalletButtonStyles = {
    btn: {
        color: "#000",
        padding: "12.5px 50px",
        fontSize: "16px",
        backgroundColor: "#fff",
        borderRadius: "25px",
        cursor: "pointer",
        fontFamily: "Cinzel",
		fontWeight: 500,
        "@media (max-width: 738px)": {
            padding: "12.5px 20px",
			maxWidth: "200px",
            display: "block",
            margin: "20px auto 0",
		}
    },
    icon: {
        cursor: "pointer"
    },
    titleWrapper: {
        borderBottom: "1px solid #ddd"
    },
    titleContent: {
        display: "flex",
        marginTop: "0",
        marginBottom: "0",
        justifyContent: "space-between"
    },
    closeButton: {
        display: "flex",
        alignItems: "center"
    },
    logoutBtn: {
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: "20px",
        borderColor: "rgb(31,199,212)",
        borderRadius: "18px",
        paddingLeft: "25px",
        paddingRight: "25px",
        borderWidth: "2px",
        fontWeight: "bold"
    },
    dialogWrapper: {
        '& .MuiPaper-root': {
            borderRadius: "20px",
            width: "100%",
            backgroundColor: "black"
        },
        '& .MuiDialog-scrollPaper': {
            backgroundColor: "rgba(136, 123, 173, 0.6)"
        },
        '& .MuiDialog-paperWidthSm': {
            maxWidth: "800px"
        }

    },
    buttonColor: {
        color: "rgb(31,199,212)"
    },
    textColor: {
        color: "rgb(40, 13, 95)",
        fontWeight: "bold"
    },
    dialogRow: {
        display: "flex",
        marginTop: "20px",
        paddingBottom: "20px",
        justifyContent: "center"
    },
    walletIcon: {
        height: "50px !important",
        marginTop: "15px",
        marginBottom: "15px"
    },
}

export default ConnectWalletButtonStyles
