import React, { useEffect, useState, useRef } from 'react'
import { makeStyles } from "@material-ui/core/styles"


import { Web3ReactProvider, useWeb3React, UnsupportedChainIdError } from '@web3-react/core'
import ReCAPTCHA from "react-google-recaptcha";
import FooterStyles from "../assets/js/components/FooterStyles"

import axios from "axios";

import mailto from "../assets/images/mail-at.svg";
import twitter from "../assets/images/twitter.svg";
import instagram from "../assets/images/instagram.svg";
import telegram from "../assets/images/telegram_1.svg";
import medium from "../assets/images/medium.svg";
import linkedin from "../assets/images/linkedin.svg";
import youtube from "../assets/images/youtube.svg";
import facebook from "../assets/images/facebook.png";
import reddit from "../assets/images/reddit.svg";
import arrow from "../assets/images/arrow.png";
import {
    BrowserRouter as Router,
    Link
  } from "react-router-dom";

const useStyles = makeStyles(FooterStyles)

function Footer(props) {
    const context = useWeb3React();
    const { account, activate } = context;
    const classes = useStyles();
    const [showRecapture, setShowRecapture] = useState(false);
    const [showSubmitRecaptcha, setShowSubmitRecaptcha] = useState(false);
    const [isVerified, setVerified] = useState(false);
    const [subscribed, setSubscribed] = useState(false)

    const refEmail = useRef();

    const onFocus = () => {
        setShowRecapture(true);
    };
    const onShowAllRecapture = () => {};

    const changeCaptcha = (value) => {
        setVerified(!!value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setShowSubmitRecaptcha(true);
    };

    const handleSubscribeClick = async () => {
        const apiUrl = 'https://api.v-empire.digital/api/v1/wallets/sendEmail';
        try {
        let response = await axios.post(apiUrl, { from: refEmail.current.value, content: "account" });
        document.querySelector('form').reset()
        setSubscribed(true)
        setTimeout(()=>{
            setSubscribed(false)
        }, 4000)
        } catch (error) {
        console.log(error);
        }
    };
    return (
        <div className={classes.footer}>
        <div className={classes.grey_content}>
            <div className={classes.form_content}>
            <div className={classes.form_title}>
                <h3 className={classes.form_h3}>Subscribe</h3>
                {/* <a onClick={(e) => {
                e.preventDefault(window.open(whitepaper));
                }} href="">
                Download Whitepaper
                </a> */}
            </div>
            <div className={classes.form_section}>
                <form className={classes.form}>
                <div className={classes.form_form_content}>
                    <input type="email" className={classes.form_email} onFocus={onFocus} ref={refEmail} placeholder="Email" />
                </div>
                {showRecapture ? (
                    <div className="rc-anchor rc-anchor-normal rc-anchor-light">
                    <div className="rc-anchor-content">
                        <span>
                        protected by <strong>reCAPTCHA</strong>
                        </span>
                        <div className="rc-anchor-pt">
                        <a href="https://www.google.com/intl/en/policies/privacy/">Privacy</a>
                        <span aria-hidden="true" role="presentation">
                            {" "}
                            -{" "}
                        </span>
                        <a href="https://www.google.com/intl/en/policies/terms/">Terms</a>
                        </div>
                    </div>
                    <div className="rc-anchor-normal-footer" onFocus={onShowAllRecapture}>
                        <div className="rc-anchor-logo-portrait" role="presentation">
                        <div className="rc-anchor-logo-img rc-anchor-logo-img-portrait"></div>
                        </div>
                        <div className="rc-anchor-pt color-black">
                        <a className="color-black" href="https://www.google.com/intl/en/policies/privacy/">
                            Privacy
                        </a>
                        <span className="color-black">-</span>
                        <a className="color-black" href="https://www.google.com/intl/en/policies/terms/">
                            Terms
                        </a>
                        </div>
                    </div>
                    </div>
                ) : (
                    <></>
                )}
                {showSubmitRecaptcha ? (
                    <div className={classes.recaptcha}>
                    <ReCAPTCHA sitekey="6LfpEEkbAAAAAJHgsveRhB_D9Cn29CPBgfDNsySq" onChange={changeCaptcha} />
                    </div>
                ) : (
                    <></>
                )}
                <div className={classes.form_submit_content} onClick={handleSubscribeClick}>
                    {/* <a className={ classes.form_submit } href={`mailto:romulus@v-empire.digital?subject=&body=${account ? account : "Not connected"}`}>Subscribe</a> */}
                    <span className={`${classes.form_submit} ` + (subscribed ? classes.form_submit_subscribed : '')}>{ subscribed ? 'Subscribed' : 'Subscribe'}</span>
                </div>
                </form>
            </div>
            </div>
            <div>
            <div><Link to="/team" className={classes.links_item}>Our Team</Link></div>
            <div><Link to="/mission" className={classes.links_item}>vE's Mission</Link></div>
            <div><Link to="/unicrypt" className={classes.links_item}>Unicrypt</Link></div>
            <div><Link to="/whitepaper" className={classes.links_item}>Whitepaper</Link></div>
            <div><Link to="/faq" className={classes.links_item}>Faq</Link></div>
            <div><Link to="/contact" className={classes.links_item}>Contact</Link></div>
            </div>
        </div>
        <div className={classes.social_content}>
          <div className={`${classes.social_a}`}>
            <a href="mailto:romulus@v-empire.digital" target="_blank">
              <span className={`${classes.social_span}`}>
                <img className={`${classes.social_image} ${classes.mailto_image}`} src={mailto} alt="img" />
              </span>
            </a>
          </div>
          <div className={`${classes.social_a}`}>
            <a href="https://twitter.com/vEmpiredigital" target="_blank">
              <span className={`${classes.social_span}`}>
                <img className={classes.social_image} src={twitter} alt="img" />
              </span>
            </a>
          </div>
          <div className={`${classes.social_a}`}>
            <a href="https://www.instagram.com/vempire.digital/" target="_blank">
              <span className={`${classes.social_span}`}>
                <img className={classes.social_image} src={instagram} alt="img" />
              </span>
            </a>
          </div>
          <div className={`${classes.social_a}}`}>
            <a href="https://www.linkedin.com/company/vempire-ddao-ltd/" target="_blank">
              <span className={`${classes.social_span}`}>
                <img className={classes.social_image} src={linkedin} alt="img" />
              </span>
            </a>
          </div>
          <div className={`${classes.social_a}`}>
            <a href="https://t.me/vEmpirediscussion" target="_blank">
              <span className={`${classes.social_span}`}>
                <img className={classes.social_image} src={telegram} alt="img" />
              </span>
            </a>
          </div>
          
          <div className={`${classes.social_a}`}>
            <a href="https://medium.com/@v-empire.digital" target="_blank">
              <span className={`${classes.social_span}`}>
                <img className={classes.social_image} src={medium} alt="img" />
              </span>
            </a>
          </div>
          <div className={`${classes.social_a}`}>
            <a href="https://www.youtube.com/channel/UCjhhTUTgN2xW7IAAXSxvHrw" target="_blank">
              <span className={`${classes.social_span}`}>
                <img className={classes.social_image} src={youtube} alt="img" />
              </span>
            </a>
          </div>
          <div className={`${classes.social_a}`}>
            <a href="https://www.facebook.com/vEmpireDDAO" target="_blank">
              <span className={`${classes.social_span}`}>
                <img className={classes.social_image} src={facebook} alt="img" />
              </span>
            </a>
          </div>
          <div className={`${classes.social_a}`}>
            <a href="https://www.reddit.com/r/vEmpireDDAO/" target="_blank">
              <span className={`${classes.social_span}`}>
                <img className={classes.social_image} src={reddit} alt="img" />
              </span>
            </a>
          </div>
      </div>
    
                  
         <div className={classes.copyright}>
         Copyright © 2021 VEMPIRE DDAO LTD. All rights reserved.<br/>
         This work is registered with the UK Copyright Service: Registration No: 284741906

        </div>
        </div>
        
    )
}

export default Footer
